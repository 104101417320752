<template>
  <div>
    <cs-button
      :id="getCompleteChecklistId"
      :disabled="dialog"
      :loading="dialog"
      @click.stop="getData()"
      :class="btnClass"
      :left-icon="checklistCommentIconDisplayed ? 'fa fa-comment' : 'fa fa-check'"
      label="Complete Checklist"
      small
    >
    </cs-button>
    <cs-form-dialog
      v-model="dialog"
      id="dlg-view-mandatory-checklist"
      :bodyId="getDlgBodyId"
      heading="Complete Checklist"
      :primaryAction="{
        label: 'Save',
        eventName: 'primary-click',
        disabled: !formControlEnabled
      }"
      @primary-click="save"
      :secondaryAction1="{
        label: 'Close',
        eventName: 'secondary1-click'
      }"
      @secondary1-click="close"
    >
      <template v-slot:cs-form-dialog-content>
        <div class="checklist view-mandatory-checklist-policy">
          <v-list v-if="checklist.title !== 'undefined' && dialog">
            <v-subheader>
              <h1>{{ checklist.title }}</h1>
            </v-subheader>
            <v-list v-for="(item, index) in changedChildren" :key="index">
              <div v-if="item.contentType === 'form-group'">
                <v-subheader v-if="item.label.indexOf(removeLabel) !== 0">
                  <vue-markdown :source="item.label" :typographer="false"></vue-markdown>
                </v-subheader>
                <div v-for="(option, index) in item.children" :key="index">
                  <v-list-item>
                    <v-list-item-action v-if="'checkbox' === option.contentType">
                      <v-checkbox
                        color="#c80063"
                        v-model="option.ticked"
                        class="cbOption"
                        :checked="option.ticked"
                        :id="`view-mandatory-checklist--${String(checklist.title).replaceAll(
                          ' ',
                          '-'
                        )}--form-group-checkbox--${index}`"
                        :disabled="!formControlEnabled"
                        @change="checklistOnChanged"
                      ></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <vue-markdown :source="option.label" :typographer="false"></vue-markdown>
                    </v-list-item-content>
                  </v-list-item>
                  <checklist-item-comment
                    :checklistItem="option"
                    :commentBtnId="`view-mandatory-checklist--${String(checklist.title).replaceAll(
                      ' ',
                      '-'
                    )}--form-group-checkbox-comment--${index}`"
                  >
                  </checklist-item-comment>
                </div>
              </div>
              <div v-else-if="item.contentType === 'checkbox'">
                <v-list-item>
                  <v-list-item-action>
                    <v-checkbox
                      color="#c80063"
                      v-model="item.ticked"
                      class="cbOption"
                      :checked="item.ticked"
                      :id="`view-mandatory-checklist--${String(checklist.title).replaceAll(
                        ' ',
                        '-'
                      )}--checkbox--${index}`"
                      :disabled="!formControlEnabled"
                      @change="checklistOnChanged"
                      testing-id="checkboxOptions"
                    ></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <vue-markdown :source="item.label" :typographer="false"></vue-markdown>
                  </v-list-item-content>
                </v-list-item>
                <checklist-item-comment
                  :checklistItem="item"
                  :commentBtnId="`view-mandatory-checklist--${String(checklist.title).replaceAll(
                    ' ',
                    '-'
                  )}--checkbox-comment--${index}`"
                >
                </checklist-item-comment>
              </div>
              <div v-else>
                <v-subheader>
                  <vue-markdown :source="item.label" :typographer="false"></vue-markdown>
                </v-subheader>
                <checklist-item-comment
                  :checklistItem="item"
                  :commentBtnId="`view-mandatory-checklist--${String(checklist.title).replaceAll(
                    ' ',
                    '-'
                  )}--content-type-comment--${index}`"
                >
                </checklist-item-comment>
              </div>
            </v-list>
          </v-list>
          <div class="pl-4">
            <div class="d-flex align-center justify-start">
              <v-checkbox
                color="#c80063"
                v-model="isCheckedAll"
                class="checkAll"
                :checked="isCheckedAll"
                id="`check-all-list`"
                @change="checkAllOnchange"
                testing-id="checkAllCheckbox"
                :disabled="archivedExcursionDetected"
              ></v-checkbox>
              <span class="check-all-label" for="`check-all-list`"> Select all </span>
            </div>
            <div class="pl-1">
              The School requires that the mandatory risk control requirements contained in this
              checklist are implemented for all excursions. I acknowledge that I have read and
              understood the measures contained in this checklist, and they have all been or will
              all be implemented.
            </div>
          </div>
        </div>
      </template>
    </cs-form-dialog>
  </div>
</template>

<script>
import * as types from '@/store/mutationTypes';
import excursionStatus from '../lib/const/excursionStatus';

export default {
  props: ['elementDisabled', 'title', 'saveDraft', 'formControlEnabled'],
  data: () => ({
    dialog: false,
    checklistChanged: true,
    removeLabel: 'The following risk controls have been selected by default.',
    originalChildren: {},
    changedChildren: {},
    isCheckedAll: false
  }),
  computed: {
    isTicked() {
      const checklist = this.$store.state.common.excursionDc.mandatoryChecklist.find(
        (l) => l.title === this.title
      );

      const checkedItem = this.getItemsByTitle(checklist);

      let itemTotal = 0;
      let checkedItemTotal = 0;

      if (checkedItem && checkedItem.length > 0) {
        checkedItem.forEach((i) => {
          if (i && i.children) {
            itemTotal += i.children.length;

            const checkedItems = i.children.filter((ic) => ic.ticked);
            if (checkedItems) {
              checkedItemTotal += checkedItems.length;
            }
          }
        });

        itemTotal += checkedItem.filter(
          (c) => (!c.children || c.children.length === 0) && c.contentType !== 'static-text'
        ).length;
        const checkedItems = checkedItem.filter((ic) => ic.ticked);
        if (checkedItems) {
          checkedItemTotal += checkedItems.length;
        }
      } else {
        checkedItemTotal = -1;
      }
      if (this.checklistChanged) {
        this.updateTickedMandatoryChecklists(this.title, itemTotal === checkedItemTotal);
      }
      return itemTotal === checkedItemTotal;
    },
    checklist() {
      return this.$store.state.common.excursionDc.mandatoryChecklist.find(
        (l) => l.title === this.title
      );
    },
    checklistCommentIconDisplayed() {
      const checklist = this.$store.state.common.excursionDc.mandatoryChecklist.find(
        (l) => l.title === this.title
      );
      let filledCommentCount = 0;
      if (checklist) {
        const checklistString = JSON.stringify(checklist);
        const emptyCommentCount = (checklistString.match(/"comment":""/g) || []).length;
        const commentCount = (checklistString.match(/"comment":/g) || []).length;
        filledCommentCount = commentCount - emptyCommentCount;
      }
      return filledCommentCount > 0;
    },
    getCompleteChecklistId() {
      return `view-mandatory-checklist--${this.title.replace(/\s*/g, '')}`;
    },
    archivedExcursionDetected() {
      return excursionStatus.ARCHIVED === this.$store.state.common.excursionDc.status;
    },
    btnClass() {
      return this.isTicked
        ? 'view-mandatory-checklist-button green-complete'
        : 'view-mandatory-checklist-button gray-incomplete';
    },
    getDlgBodyId() {
      return `dlg-view-mandatory-checklist-${this.title.replace(/\s*/g, '')}`;
    }
  },
  methods: {
    getData() {
      this.dialog = true;
      this.originalChildren = this.getItemsByTitle(this.checklist);
      this.changedChildren = this.$app.lodash.cloneDeep(this.originalChildren);
    },
    save() {
      this.dialog = false;
      const checklistModified = !this.$app.lodash.isEqual(
        this.originalChildren,
        this.changedChildren
      );

      if (checklistModified) {
        this.$store.commit(types.COMMON_SET_MANDATORY_CHECKLIST, {
          title: this.title,
          checklist: this.changedChildren
        });
        this.$app.eventBus.$emit('setAgreementConfirmationTick');
        this.saveDraft(null, true);
      }
    },
    close() {
      this.dialog = false;
      this.isCheckedAll = false;
    },
    getItemsByTitle(obj) {
      if (!obj.Strategy) {
        return [];
      }
      const checklist = obj.Strategy.data[Object.keys(obj.Strategy.data)].children;
      const result = this.$app.lodash.cloneDeep(checklist);
      return result;
    },
    updateTickedMandatoryChecklists(checklistTitle, ticked) {
      if (
        this.$store.state.common.tickedMandatoryChecklists.length ===
          this.$store.state.common.mandatoryChecklistsLength &&
        this.checklistChanged === true
      ) {
        const checklist = this.$store.state.common.tickedMandatoryChecklists.find(
          (obj) => obj.title === checklistTitle
        );
        if (checklist && checklist.ticked !== ticked) {
          this.$store.commit(types.COMMON_SET_MANDATORY_CHECKLISTS_TICKED_BY_TITLE, {
            title: checklistTitle,
            ticked
          });
        }
      } else if (
        this.$store.state.common.tickedMandatoryChecklists.length <
        this.$store.state.common.mandatoryChecklistsLength
      ) {
        const tickedChecklist = {
          title: checklistTitle,
          ticked
        };
        this.$store.commit(types.COMMON_ADD_TICKED_MANDATORY_CHECKLISTS, tickedChecklist);
        this.checklistChanged = false;
      }
    },
    checkAllOnchange(value) {
      if (value) {
        this.changedChildren.forEach((item) => {
          const localItem = item;
          if (localItem.contentType === 'form-group') {
            localItem.children.forEach((option) => {
              const localOption = option;
              localOption.ticked = true;
            });
          } else if (localItem.contentType === 'checkbox') {
            localItem.ticked = true;
          }
        });
        this.changedChildren = this.$app.lodash.cloneDeep(this.changedChildren);
        this.$store.commit(types.COMMON_SET_ALL_MANDATORY_CHECKLISTS_TICKED, true);
      }
    },
    checklistOnChanged(value) {
      if (!value) {
        this.isCheckedAll = false;
      }
    }
  },
  watch: {
    isTicked() {
      this.checklistChanged = true;
    }
  }
};
</script>

<style scoped>
.checklist {
  height: 600px;
}

.view-mandatory-checklist-policy h1 {
  color: gray;
  border-bottom: 3px solid #ff7f32;
  width: 100%;
}

.view-mandatory-checklist-policy h2 {
  color: #ff7f32;
}

.view-mandatory-checklist-policy .content {
  max-width: 98%;
}

.view-mandatory-checklist-button {
  font-weight: bold;
  font-size: 16px;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  text-transform: none !important;
}

.green-complete ::v-deep .cs-button-icon-left {
  border-radius: 50% !important;
  padding: 2px !important;
  border: 2px solid #28a745 !important;
  color: #28a745 !important;
}

.gray-incomplete ::v-deep .cs-button-icon-left {
  border-radius: 50% !important;
  padding: 2px !important;
  border: 2px solid #d3d3d3 !important;
  color: #d3d3d3 !important;
}

.check-all-label {
  display: inline-block;
  padding-top: 0.4rem;
  font-size: 1rem;
  font-weight: bold;
}
</style>
