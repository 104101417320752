var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('div',{staticClass:"search-container"},[_c('v-flex',{attrs:{"xs4":""}},[_c('v-select',{staticClass:"type-dropdown excursion-selection",attrs:{"xs5":"","items":_vm.listActivityTypes,"item-text":"name","item-value":"name","label":"Select an Activity Type","id":"selectedActivityType","testing-id":"selected-activity-type","solo":"","return-object":true,"clearable":_vm.isClearable,"disabled":!_vm.formControlEnabled},on:{"change":_vm.resetSelectedClass},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('div',{attrs:{"id":("activity-risk-category--activity-type--option-" + (String(
              data.item.name
            ).replaceAll(' ', '')))}},[_vm._v(" "+_vm._s(data.item.name)+" ")])]}}]),model:{value:(_vm.selectedActivityType),callback:function ($$v) {_vm.selectedActivityType=$$v},expression:"selectedActivityType"}})],1),_c('div',{staticClass:"search-arrow"},[_c('v-icon',{attrs:{"medium":""}},[_vm._v("chevron_right")])],1),_c('v-flex',{attrs:{"xs4":""}},[_c('v-select',{staticClass:"type-dropdown excursion-selection",attrs:{"id":"activity-risk-category--activity-class--selection","items":_vm.listActivityClasses,"item-text":"name","item-value":"name","label":_vm.placeholderActivityRiskClass,"solo":"","clearable":_vm.isClearable,"return-object":true,"disabled":!_vm.formControlEnabled,"menu-props":{
          maxHeight: 400
        }},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('div',{attrs:{"id":("activity-risk-category--activity-class--option-" + (String(
              data.item.name
            ).replaceAll(' ', '')))}},[_vm._v(" "+_vm._s(data.item.name)+" ")])]}}]),model:{value:(_vm.selectedActivityClass),callback:function ($$v) {_vm.selectedActivityClass=$$v},expression:"selectedActivityClass"}})],1)],1),_c('select',{staticClass:"js-example-basic-single excursion-selection safe-select2-dropdown",staticStyle:{"margin-left":"2px","width":"calc(100% - 4px)"},attrs:{"name":"selectActivityRisk","id":"selectActivityRisk","disabled":!_vm.formControlEnabled,"aria-labelledby":"selectActivityRisk"}},[_c('option',{attrs:{"value":""}}),_vm._l((_vm.filterActivityRisks(
        _vm.selectedActivityType,
        _vm.selectedActivityClass
      )),function(riskClass,cindex){return _c('optgroup',{key:cindex,attrs:{"label":riskClass.name}},_vm._l((riskClass.risks),function(risk,rindex){return _c('option',{key:rindex,attrs:{"title":riskClass.name + ',' + riskClass.typeName,"disabled":risk.disabled},domProps:{"value":risk.name}},[_vm._v(" "+_vm._s(risk.name)+" ")])}),0)})],2),(!_vm.selectedActivityClasses || 0 >= _vm.selectedActivityClasses.length)?_c('div',{staticClass:"builder-activity-sub"}):_vm._e(),(0 < _vm.selectedActivityClasses.length)?_c('div',{staticClass:"activity sub-category-section-margin builder-activity-sub activity-category-class"},_vm._l((_vm.selectedActivityClasses[0].types),function(riskType,tindex){return _c('div',{key:tindex},_vm._l((riskType.classes),function(riskClass){return _c('div',{key:_vm.setKey(riskClass),staticClass:"sub-category-section"},[_c('v-layout',{attrs:{"align-center":"","justify-space-between":""}},[_c('v-flex',{attrs:{"xs4":""}},[_c('v-layout',{attrs:{"align-center":"","justify-space-between":""}},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(riskClass.name))])])],1),_c('v-spacer'),_c('v-flex',{staticClass:"sub-category-view-policy",attrs:{"xs8":""}},[_c('div',{staticClass:"float-right"},[(_vm.checklistAndPolicyDisplayed)?_c('view-policy',{attrs:{"categoryName":_vm.selectedActivityClasses[0].name,"riskTypeName":riskType.name,"riskClassName":riskClass.name,"elementDisabled":!riskClass.risks,"activitySupervisorScrollDisplayed":true}}):_vm._e()],1),_c('div',{staticClass:"float-right"},[(_vm.checklistAndPolicyDisplayed)?_c('complete-checklist',{ref:"completeChecklist",refInFor:true,attrs:{"categoryName":_vm.selectedActivityClasses[0].name,"riskTypeName":riskType.name,"riskClassName":riskClass.name,"associatedChecklistCompleted":_vm.getTicked(riskClass),"formControlEnabled":_vm.formControlEnabled,"elementDisabled":(riskClass.risks &&
                    _vm.elementDisabled &&
                    riskType.name === _vm.checklistFetchActionRiskTypeName &&
                    riskClass.name === _vm.checklistFetchActionRiskClassName) ||
                  !riskClass.risks,"checklistFetchActionCategoryName":_vm.selectedActivityClasses[0].name,"checklistFetchActionRiskTypeName":_vm.checklistFetchActionRiskTypeName,"checklistFetchActionRiskClassName":_vm.checklistFetchActionRiskClassName,"checklistFetchActionInProgress":_vm.checklistFetchActionInProgress,"saveDraft":_vm.saveDraft}}):_vm._e()],1)])],1),(!riskClass.risks)?_c('v-layout',{attrs:{"justify-center":""}},[_c('v-select',{staticClass:"type-dropdown risk-class-select excursion-selection empty-risk-selection",attrs:{"items":_vm.listActivityRisks('', riskClass.name, riskType.name),"item-text":"name","item-value":"name","item-disabled":"disabled","solo":"","id":"changeActivityPreSelectedRisk","testing-id":"list-risk","label":"Select a risk"},on:{"change":function($event){return _vm.changeActivitySelectedRiskId(riskClass.name, riskType.name, {}, $event)}},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('div',{attrs:{"id":("activity-risk-category--activity-pre-select-risk--option-" + (String(
                  data.item.name
                ).replaceAll(' ', '')))}},[_vm._v(" "+_vm._s(data.item.name)+" ")])]}}],null,true)}),_c('div',[_c('v-btn',{staticClass:"remove-icon",attrs:{"icon":"","disabled":!_vm.formControlEnabled,"id":_vm.getActivityRiskDeleteId(riskType, riskClass, null)},nativeOn:{"click":function($event){return _vm.displayDeleteRiskConfirm(riskType, riskClass, null)}}},[_c('v-icon',[_vm._v("delete")])],1)],1)],1):_vm._e(),(riskClass.risks && 0 < riskClass.risks.length)?_c('div',_vm._l((riskClass.risks),function(selectedRisk,index){return _c('v-layout',{key:index,attrs:{"justify-center":""}},[_c('v-select',{staticClass:"type-dropdown risk-class-select excursion-selection",attrs:{"items":_vm.listActivityRisks(selectedRisk.name, riskClass.name, riskType.name),"item-text":"name","item-value":"name","item-disabled":"disabled","solo":"","value":selectedRisk.name,"id":_vm.getActivityRiskId(riskType, riskClass, selectedRisk),"testing-id":"list-risk","disabled":!_vm.formControlEnabled},on:{"change":function($event){return _vm.changeActivitySelectedRiskId(riskClass.name, riskType.name, selectedRisk, $event)}},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('div',{attrs:{"id":("activity-risk-category--activity-selected-risk--option-" + (String(
                    data.item.name
                  ).replaceAll(' ', '')))}},[_vm._v(" "+_vm._s(data.item.name)+" ")])]}}],null,true)}),_c('div',[_c('v-btn',{staticClass:"remove-icon",attrs:{"icon":"","disabled":!_vm.formControlEnabled,"id":_vm.getActivityRiskDeleteId(riskType, riskClass, selectedRisk)},nativeOn:{"click":function($event){return _vm.displayDeleteRiskConfirm(riskType, riskClass, selectedRisk)}}},[_c('v-icon',[_vm._v("delete")])],1)],1)],1)}),1):_vm._e()],1)}),0)}),0):_vm._e(),_c('cs-form-dialog',{attrs:{"id":"dlg-activity-risk-category","bodyId":"dlg-body-activity-risk-category","heading":"Delete Risk Category Type","primaryAction":{
      label: 'Delete',
      eventName: 'primary-click'
    },"secondaryAction1":{
      label: 'Cancel',
      eventName: 'secondary1-click'
    }},on:{"primary-click":_vm.removeActivitySelectedRiskId,"secondary1-click":function($event){_vm.deleteActivityRiskDisplayed = false}},scopedSlots:_vm._u([{key:"cs-form-dialog-content",fn:function(){return [_c('v-card',[_c('v-card-text',[_vm._v("Are you Sure?")])],1)]},proxy:true}]),model:{value:(_vm.deleteActivityRiskDisplayed),callback:function ($$v) {_vm.deleteActivityRiskDisplayed=$$v},expression:"deleteActivityRiskDisplayed"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }