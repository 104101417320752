var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view-all-mandatory-checklist"},[_c('cs-button',{attrs:{"id":"view-all-mandatory-checklist--view-checklist","label":"View Checklist","disabled":_vm.disabled},on:{"click":function($event){$event.stopPropagation();return _vm.getData()}}}),_c('cs-form-dialog',{attrs:{"id":"dlg-view-all-mandatory-checklist","bodyId":"dlg-view-all-mandatory-checklist-body","heading":"Complete Checklist"},scopedSlots:_vm._u([{key:"cs-form-dialog-content",fn:function(){return [_c('div',{staticClass:"checklist-content"},_vm._l((_vm.checklists),function(checklist,index){return _c('v-list',{key:index},[_c('v-subheader',[_c('h1',[_vm._v(_vm._s(checklist.title))])]),_vm._l((_vm.getItemsByTitle(checklist)),function(item,index){return _c('v-list',{key:index},[(item.contentType === 'form-group')?_c('div',[(item.label.indexOf(_vm.removeLabel) !== 0)?_c('v-subheader',[_c('vue-markdown',{attrs:{"source":item.label,"typographer":false}})],1):_vm._e(),_vm._l((item.children),function(option,index){return _c('div',{key:index},[_c('v-list-item',[('checkbox' === option.contentType)?_c('v-list-item-action',[_c('v-checkbox',{staticClass:"cbOption",attrs:{"color":"#c80063","checked":option.ticked,"disabled":true,"id":("view-all-mandatory-checklist--" + (String(checklist.title).replaceAll(
                        ' ',
                        '-'
                      )) + "--form-group-checkbox--" + index)},model:{value:(option.ticked),callback:function ($$v) {_vm.$set(option, "ticked", $$v)},expression:"option.ticked"}})],1):_vm._e(),_c('v-list-item-content',[_c('label',{staticClass:"text",attrs:{"for":("view-all-mandatory-checklist--" + (String(checklist.title).replaceAll(
                        ' ',
                        '-'
                      )) + "--form-group-checkbox--" + index)}},[_c('vue-markdown',{attrs:{"source":option.label,"typographer":false}})],1)])],1),_c('checklist-item-comment',{attrs:{"checklistItem":option,"commentDisabled":true,"commentBtnId":("view-all-mandatory-checklist--" + (String(
                    checklist.title
                  ).replaceAll(' ', '-')) + "--form-group-checkbox-comment--" + index)}})],1)})],2):(item.contentType === 'checkbox')?_c('div',[_c('v-list-item',[_c('v-list-item-action',[_c('v-checkbox',{staticClass:"cbOption",attrs:{"color":"#c80063","checked":item.ticked,"id":("view-all-mandatory-checklist--" + (String(checklist.title).replaceAll(
                      ' ',
                      '-'
                    )) + "--checkbox--" + index),"disabled":true},model:{value:(item.ticked),callback:function ($$v) {_vm.$set(item, "ticked", $$v)},expression:"item.ticked"}})],1),_c('v-list-item-content',[_c('label',{staticClass:"text",attrs:{"for":("view-all-mandatory-checklist--" + (String(checklist.title).replaceAll(
                      ' ',
                      '-'
                    )) + "--checkbox--" + index)}},[_c('vue-markdown',{attrs:{"source":item.label,"typographer":false}})],1)])],1),_c('checklist-item-comment',{attrs:{"checklistItem":item,"commentDisabled":true,"commentBtnId":("view-all-mandatory-checklist--" + (String(
                  checklist.title
                ).replaceAll(' ', '-')) + "--checkbox-comment--" + index)}})],1):_c('div',[_c('v-subheader',[_c('vue-markdown',{attrs:{"source":item.label,"typographer":false}})],1),_c('checklist-item-comment',{attrs:{"checklistItem":item,"commentDisabled":true,"commentBtnId":("view-all-mandatory-checklist--" + (String(
                  checklist.title
                ).replaceAll(' ', '-')) + "--content-type-comment--" + index)}})],1)])})],2)}),1)]},proxy:true}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }