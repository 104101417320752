<template>
  <v-card flat class="system-user-list">
    <v-btn
      @click.native="showNewUserModal('new', '')"
      id="newClient"
      class="normal-btn white--text create-new-client-button primary"
      >Create New User</v-btn
    >
    <div class="new-client-button-clear"></div>
    <v-layout class="search">
      <v-flex xs2 class="filter-by">FILTER BY:</v-flex>
      <v-flex xs4>
        <v-select
          placeholder="Select Client ACL Role"
          :items="clientAclRoles"
          single-line
          v-model="searchClientAclRole"
          @change="getUserFilterData(true)"
          id="system-user-list--client-acl-role"
        >
          <template slot="item" slot-scope="data">
            <span :id="`system-user-list--client-acl-role-item-${data.item.text}`">
              {{ data.item.text }}
            </span>
          </template>
        </v-select>
      </v-flex>
      <v-flex xs4>
        <v-text-field
          @keydown.space.prevent
          label="User Email"
          id="system-user-list--user-email"
          class="excursion-filter-search excursion-input"
          single-line
          type="text"
          v-model="searchUserEmail"
          @keyup.enter="getUserFilterData(true)"
        ></v-text-field>
      </v-flex>
      <v-flex xs4>
        <v-text-field
          label="Search Client Name"
          id="system-user-list--client-name"
          class="excursion-filter-search excursion-input"
          append-icon="search"
          single-line
          type="text"
          v-model="searchClient"
          @click:append="getUserFilterData(true)"
          @keyup.enter="getUserFilterData(true)"
        ></v-text-field>
      </v-flex>
    </v-layout>
    <v-data-table
      :headers="userHeaders"
      :options.sync="pagination"
      class="elevation-2 excursion-table"
      :footer-props="footerProps"
      :items="userList"
      :loading="loading"
      :server-items-length="userListTotal"
      no-data-text="No user was found"
    >
      <template slot="item" slot-scope="props">
        <tr>
          <td class="excursion-name-cell">
            <a
              class="client-id-label"
              :id="`system-user-list--update-user--link-${props.index}`"
              @click="showUpdateUserModal(props.item.id)"
              >{{ props.item.id }}</a
            >
          </td>
          <td class="text-xs-left">{{ props.item.email }}</td>
          <td class="text-xs-left">{{ getUserClientName(props.item.clientList) }}</td>
          <td class="text-xs-left">{{ props.item.created_at }}</td>
          <td class="text-xs-left">
            <v-tooltip v-if="props.item.enabled" left color="#ffffff">
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  v-on="{ ...tooltip }"
                  icon
                  :id="getUserDisableBtnId(props.item.id)"
                  @click.native="
                    displayUserEnabledConfirmationDialog(props.item.id, props.item.enabled)
                  "
                >
                  <i class="fas fa-check action-tooltip-icon"></i>
                </v-btn>
              </template>
              <span class="action-tooltip-content">Disable</span>
            </v-tooltip>
            <v-tooltip v-if="!props.item.enabled" left color="#ffffff">
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  v-on="{ ...tooltip }"
                  icon
                  :id="getUserEnableBtnId(props.item.id)"
                  @click.native="
                    displayUserEnabledConfirmationDialog(props.item.id, props.item.enabled)
                  "
                >
                  <i class="fas fa-ban action-tooltip-icon"></i>
                </v-btn>
              </template>
              <span class="action-tooltip-content">Enable</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>
    <system-user-individual-modal
      v-if="userModalDialog"
      :propSystemUserIndividualModalRefreshed="propSystemUserIndividualModalRefreshed"
      :propUserId="propUserId"
      @reloadUsers="getUserFilterData"
    />
    <div>
      <cs-form-dialog
        v-model="userEnabledConfirmationDialogDisplayed"
        bodyId="dlg-confirm-system-user-list"
        heading="User Confirmation"
        :primaryAction="{
          label: userEnabledConfirmationDialogButtonLabel,
          eventName: 'primary-click'
        }"
        :secondaryAction1="{ label: 'Cancel', eventName: 'secondary1-click' }"
        @primary-click="confirmUserEnabledToggle"
        @secondary1-click="userEnabledConfirmationDialogDisplayed = false"
      >
        <template v-slot:cs-form-dialog-content>
          <v-card>
            <v-card-text>{{ userEnabledConfirmationDialogMessage }}</v-card-text>
          </v-card>
        </template>
      </cs-form-dialog>
    </div>
  </v-card>
</template>

<script>
import * as types from '@/store/mutationTypes';

import aclRole from '../lib/const/aclRole';
import systemUserTableColumn from '../lib/const/systemUserTableColumn';

export default {
  props: [],
  data() {
    return {
      userEnabledConfirmationDialogDisplayed: false,
      userEnabledConfirmationDialogMessage: '',
      userEnabledConfirmationDialogButtonLabel: '',
      userEnabledConfirmationDialogUserId: 0,
      userEnabledConfirmationDialogUserEnabled: true,
      clientAclRoles: [
        { value: aclRole.CLIENT_ADMIN, text: aclRole.CLIENT_ADMIN },
        { value: aclRole.CLIENT_ADMIN_ASSISTANT, text: aclRole.CLIENT_ADMIN_ASSISTANT },
        { value: aclRole.CLIENT_APPROVER, text: aclRole.CLIENT_APPROVER },
        { value: aclRole.CLIENT_GROUP_ADMIN, text: aclRole.CLIENT_GROUP_ADMIN },
        { value: aclRole.CLIENT_GROUP_APPROVER, text: aclRole.CLIENT_GROUP_APPROVER },
        { value: aclRole.CLIENT_GROUP_USER, text: aclRole.CLIENT_GROUP_USER },
        { value: aclRole.CLIENT_GROUP_WATCHER, text: aclRole.CLIENT_GROUP_WATCHER },
        { value: aclRole.CLIENT_LITE_USER, text: aclRole.CLIENT_LITE_USER },
        { value: aclRole.CLIENT_USER, text: aclRole.CLIENT_USER },
        { value: aclRole.CLIENT_WATCHER, text: aclRole.CLIENT_WATCHER }
      ],
      userModalDialog: false,
      propSystemUserIndividualModalRefreshed: true,
      propUserId: '',
      footerProps: {
        'items-per-page-options': [10, 30, 50]
      },
      pagination: {
        itemsPerPage: 30,
        page: 1,
        sortDesc: [false],
        sortBy: ['']
      },
      userHeaders: [
        {
          text: systemUserTableColumn.USER_ID.label,
          value: systemUserTableColumn.USER_ID.tableColumnRef,
          class: 'header'
        },
        {
          text: systemUserTableColumn.USER_EMAIL.label,
          value: systemUserTableColumn.USER_EMAIL.tableColumnRef,
          class: 'header'
        },
        {
          text: systemUserTableColumn.CLIENT.label,
          value: systemUserTableColumn.CLIENT.tableColumnRef,
          class: 'header',
          sortable: false
        },
        {
          text: systemUserTableColumn.DATE_CREATED.label,
          value: systemUserTableColumn.DATE_CREATED.tableColumnRef,
          class: 'header'
        },
        {
          text: systemUserTableColumn.ACTION.label,
          value: systemUserTableColumn.ACTION.tableColumnRef,
          class: 'header',
          sortable: false
        }
      ],
      querystringRefToTableColumnRefMap: {},
      tableColumnRefToQuerystringRefMap: {},
      searchUserEmail: '',
      searchClientAclRole: '',
      searchClient: ''
    };
  },
  computed: {
    userList() {
      return this.$store.state.common.userList.data;
    },
    loading() {
      return this.$store.state.common.userList.loading;
    },
    userListTotal() {
      return this.$store.state.common.userList.total;
    }
  },
  methods: {
    getUserDisableBtnId(id) {
      return `system-user-list--system-user-disable--button-${id}`;
    },
    getUserEnableBtnId(id) {
      return `system-user-list--system-user-enable--button-${id}`;
    },
    displayUserEnabledConfirmationDialog(userId, enabled) {
      if (enabled) {
        this.userEnabledConfirmationDialogMessage = 'Are you sure you want to disable this user?';
        this.userEnabledConfirmationDialogButtonLabel = 'Disable';
      } else {
        this.userEnabledConfirmationDialogMessage = 'Are you sure you want to enable this user?';
        this.userEnabledConfirmationDialogButtonLabel = 'Enable';
      }
      this.userEnabledConfirmationDialogUserId = userId;
      this.userEnabledConfirmationDialogDisplayed = true;
      this.userEnabledConfirmationDialogUserEnabled = !enabled;
    },
    async confirmUserEnabledToggle() {
      const store = this.$store;
      const requestParam = {
        enabled: this.userEnabledConfirmationDialogUserEnabled
      };
      const argHash = {
        id: this.userEnabledConfirmationDialogUserId,
        payload: requestParam,
        store: this.$store
      };
      await this.$app.stbApiAdapter.putUserEnabled(argHash);
      store.dispatch('common/toggleUserEnabled', this.userEnabledConfirmationDialogUserId);
      this.userEnabledConfirmationDialogDisplayed = false;
    },
    showNewUserModal() {
      this.userModalDialog = true;
      this.propSystemUserIndividualModalRefreshed = !this.propSystemUserIndividualModalRefreshed;
      this.propUserId = '';
    },
    showUpdateUserModal(userId) {
      this.userModalDialog = true;
      this.propSystemUserIndividualModalRefreshed = !this.propSystemUserIndividualModalRefreshed;
      this.propUserId = `${userId}`;
    },
    getUserClientName(clientList) {
      return clientList && clientList.length > 0 && clientList[0].metadata
        ? clientList[0].metadata.name
        : '';
    },
    resetPage() {
      this.pagination.itemsPerPage = 30;
      this.pagination.page = 1;
      this.pagination.sortDesc[0] = true;
      this.pagination.sortBy[0] = '';
    },
    async getUserFilterData(isResetPage = false) {
      if (isResetPage) {
        this.resetPage();
      }
      this.$store.commit(types.COMMON_SET_USER_LIST_LOADING, true);
      const filterParam = {
        's-user-email': this.searchUserEmail.trim(),
        's-client-acl-role': this.searchClientAclRole,
        's-client-name': this.searchClient,
        page: this.pagination.page,
        length: this.pagination.itemsPerPage
      };

      Object.keys(filterParam).forEach((key) => {
        if (filterParam[key] === '') {
          delete filterParam[key];
        }
      });
      const searchQuery = JSON.parse(JSON.stringify(filterParam));
      if (this.tableColumnRefToQuerystringRefMap[this.pagination.sortBy[0]]) {
        searchQuery.order = this.tableColumnRefToQuerystringRefMap[this.pagination.sortBy[0]];
        searchQuery.direction = this.pagination.sortDesc[0] === false ? 'ASC' : 'DESC';
        // eslint-disable-next-line prefer-destructuring
        filterParam.order = this.pagination.sortBy[0];
        filterParam.direction = searchQuery.direction;
      }

      const argHash = {
        stbApiAdapter: this.$app.stbApiAdapter,
        filterParam,
        store: this.$store
      };
      await this.$store.dispatch('common/getUserList', argHash);
      this.$router.push({ query: searchQuery }, () => {});
    }
  },
  mounted() {
    Object.keys(systemUserTableColumn).forEach((key) => {
      this.querystringRefToTableColumnRefMap[systemUserTableColumn[key].querystringRef] =
        systemUserTableColumn[key].tableColumnRef;
      this.tableColumnRefToQuerystringRefMap[systemUserTableColumn[key].tableColumnRef] =
        systemUserTableColumn[key].querystringRef;
    });
    const clientAclRolesValues = this.clientAclRoles.map((item) => item.value);
    if (Object.keys(this.$route.query).length !== 0) {
      const {
        's-user-email': parsedSearchUserEmail,
        's-client-acl-role': parsedSearchClientAclRole,
        's-client-name': parsedSearchClientName,
        page: parsedSearchPage,
        length: parsedSearchLength,
        direction: parsedSearchDirection,
        order: parsedSortBy
      } = this.$route.query;
      if (parsedSearchUserEmail && String(parsedSearchUserEmail).trim().length !== 0) {
        this.searchUserEmail = parsedSearchUserEmail;
      }
      if (parsedSearchClientName && String(parsedSearchClientName).trim().length !== 0) {
        this.searchClient = parsedSearchClientName;
      }
      if (
        parsedSearchClientAclRole &&
        String(parsedSearchClientAclRole).trim().length !== 0 &&
        clientAclRolesValues.indexOf(parsedSearchClientAclRole) !== -1
      ) {
        this.searchClientAclRole = parsedSearchClientAclRole;
      }
      if (
        parsedSearchLength === '10' ||
        parsedSearchLength === '30' ||
        parsedSearchLength === '50'
      ) {
        this.pagination.itemsPerPage = parseInt(parsedSearchLength, 10);
      } else {
        this.pagination.itemsPerPage = 30;
      }
      if (!Number.isNaN(parseInt(parsedSearchPage, 10)) && parseInt(parsedSearchPage, 10) > 0) {
        this.pagination.page = parseInt(parsedSearchPage, 10);
      }
      if (
        systemUserTableColumn.USER_ID.querystringRef === parsedSortBy ||
        systemUserTableColumn.USER_EMAIL.querystringRef === parsedSortBy ||
        systemUserTableColumn.CLIENT.querystringRef === parsedSortBy ||
        systemUserTableColumn.DATE_CREATED.querystringRef === parsedSortBy ||
        systemUserTableColumn.ACTION.querystringRef === parsedSortBy
      ) {
        this.pagination.sortBy[0] = this.querystringRefToTableColumnRefMap[parsedSortBy];
        if (parsedSearchDirection === 'DESC' || parsedSearchDirection === 'ASC') {
          this.pagination.sortDesc[0] = parsedSearchDirection === 'DESC';
        }
      }
    }
  },
  watch: {
    pagination: {
      handler() {
        this.getUserFilterData();
      }
    }
  }
};
</script>
