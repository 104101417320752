<template>
  <v-layout class="pre-departure-check-list">
    <v-btn
      class="normal-btn white--text predeparture-checklist-button primary"
      @click.native="getData()"
      id="btnViewOrExportPredepartureChecklist"
      testing-id="btnViewOrExportPredepartureChecklist"
      :disabled="predepartureChecklistExportActionEnabled"
    >
      <v-icon v-if="predepartureChecklistCompleted">fa fa-check</v-icon>
      <font>Pre-Departure Checklist</font>
    </v-btn>
    <cs-form-dialog
      v-model="dialog"
      id="dlg-pre-departure-check-list"
      bodyId="dlg-pre-departure-check-list-body"
      heading="Pre-Departure Checklist"
      :primaryAction="{
        label: 'Save Pre-departure',
        eventName: 'primary-click',
        disabled: predepartureSaveActionInProgress || archivedExcursionDetected,
        loading: predepartureSaveActionInProgress
      }"
      @primary-click="save()"
      :secondaryAction1="{
        label: 'Export',
        eventName: 'secondary1-click',
        disabled: predepartureDownloadActionInProgress && !predepartureExportActionCompleted,
        loading: predepartureDownloadActionInProgress && !predepartureExportActionCompleted
      }"
      @secondary1-click="displayPresaveConfirmationDialog"
      :secondaryAction2="{
        label: 'Close',
        eventName: 'secondary2-click'
      }"
      @secondary2-click="close"
      @input="onPredepartureDialogChange"
    >
      <template v-slot:cs-form-dialog-content>
        <div class="predeparture-check-list">
          <div v-if="checklistChildrenDisplayed()">
            <v-list v-for="(item, index) in items" :key="index">
              <v-subheader class="subhealder">
                <vue-markdown :source="item.label" :typographer="false"></vue-markdown>
              </v-subheader>
              <div v-for="(option, index) in item.children" :key="index">
                <v-layout>
                  <v-checkbox
                    :id="getPreCheckId(option, index, item.label)"
                    color="#c80063"
                    v-model="option.ticked"
                    class="pre-check"
                    :checked="option.ticked"
                    :disabled="archivedExcursionDetected"
                  ></v-checkbox>
                  <v-flex
                    :id="getPreCheckLabelId(option, index, item.label)"
                    class="pre-check-desc"
                    @click.stop="clickItemTitle(option)"
                    testing-id="clickItemTitle"
                  >
                    <vue-markdown :source="option.label" :typographer="false"></vue-markdown>
                  </v-flex>
                </v-layout>
                <checklist-item-comment
                  class="pre-checklist-item-comment"
                  :checklistItem="option"
                  :commentBtnId="`view-pre-departture-checklist--${String(item.label)
                    .replaceAll(' ', '-')
                    .replaceAll('*', '')}---comment--${index}`"
                ></checklist-item-comment>
              </div>
            </v-list>
          </div>
          <div v-if="!checklistChildrenDisplayed()">
            <v-list v-for="(item, index) in items" :key="index">
              <v-subheader class="subhealder">
                <h3>{{ item.item }}</h3>
              </v-subheader>
              <v-layout>
                <v-checkbox
                  :id="getPreCheckId(item, index, item.item)"
                  color="#c80063"
                  v-model="item.ticked"
                  class="pre-check"
                  :checked="item.ticked"
                  :disabled="archivedExcursionDetected"
                ></v-checkbox>
                <v-flex
                  class="pre-check-desc"
                  :id="getPreCheckLabelId(item, index, item.item)"
                  @click.stop="clickItemTitle(item)"
                  testing-id="clickItemTitle"
                >
                  {{ item.description }}
                </v-flex>
              </v-layout>
              <checklist-item-comment
                :checklistItem="item"
                :commentBtnId="`view-pre-departture-checklist--${String(item.item)
                  .replaceAll(' ', '-')
                  .replaceAll('*', '')}---comment--${index}`"
              >
              </checklist-item-comment>
            </v-list>
          </div>
        </div>
      </template>
    </cs-form-dialog>

    <cs-form-dialog
      v-model="presaveConfirmationDialogDisplayed"
      id="dlg-pre-departure-check-list-confirm"
      bodyId="dlg-pre-departure-check-list-confirm-body"
      heading="Pre-Departure Checklist Export"
      :primaryAction="{
        label: 'Save',
        eventName: 'primary-click',
        disabled: !formControlEnabled
      }"
      @primary-click="onConfirmExportSave(true)"
      :secondaryAction1="{
        label: 'Close',
        eventName: 'secondary1-click'
      }"
      @secondary1-click="onConfirmExportSave(false)"
    >
      <template v-slot:cs-form-dialog-content>
        Changes have been made to this excursion. Do you want to save?
      </template>
    </cs-form-dialog>
  </v-layout>
</template>

<script>
import * as types from '@/store/mutationTypes';
import excursionStatus from '../lib/const/excursionStatus';

export default {
  props: ['predepartureChecklistExportActionEnabled', 'formControlEnabled'],
  data: () => ({
    dialog: false,
    predepartureDownloadActionInProgress: false,
    predepartureSaveActionInProgress: false,
    predepartureExportActionCompleted: false,
    items: [],
    presaveConfirmationDialogDisplayed: false
  }),
  computed: {
    predepartures() {
      return this.$app.lodash.cloneDeep(this.$store.state.common.excursionDc.predepartures);
    },
    predepartureChecklistCompleted() {
      return this.$store.state.common.excursionDc.predepartureChecklistCompleted;
    },
    archivedExcursionDetected() {
      return excursionStatus.ARCHIVED === this.$store.state.common.excursionDc.status;
    }
  },
  methods: {
    async getData() {
      this.dialog = true;
      this.items = this.$app.lodash.cloneDeep(this.$store.state.common.excursionDc.predepartures);
      if (!this.items || this.items.length <= 0) {
        const excursionCommonAdapter = new this.$app.excursionCommon.Adapter({
          store: this.$store,
          app: this.$app
        });
        const genericFrcs = excursionCommonAdapter.getRiskCategoryParams();
        const activityFrcs = excursionCommonAdapter.getActivityParams();
        // frc stands for: factor risk category
        const frcs = genericFrcs.concat(activityFrcs) || [];
        frcs.push([this.$store.state.common.excursionDc.typeId]);
        let response;

        const argHash = {
          stbApiAdapter: this.$app.stbApiAdapter,
          store: this.$store,
          queryParam: this.$route.query,
          payload: {
            'risk-categories': frcs
          }
        };

        if (frcs) {
          response = await this.$app.stbApiAdapter.postPredepartureStrategies(argHash);
          if (response && response.checklist) {
            this.items = [];
            this.items = this.$app.lodash.cloneDeep(response.checklist);
            this.items.forEach((p) => {
              const localP = p;
              localP.ticked = false;
            });
            this.$store.commit(
              types.COMMON_SET_PREDEPARTURE,
              this.$app.lodash.cloneDeep(response.checklist)
            );
          } else if (response && response.length > 0) {
            this.$store.commit(types.COMMON_SET_PREDEPARTURE, this.$app.lodash.cloneDeep(response));
          }
        }
      }
    },
    close() {
      this.dialog = false;
      this.items = [];
    },
    async displayPresaveConfirmationDialog() {
      if (this.valuesChanged()) {
        this.presaveConfirmationDialogDisplayed = true;
      } else {
        await this.exportPredeparture();
      }
    },
    valuesChanged() {
      let valuesChanged = false;
      let originalChecked = [];
      let newChecked = [];
      if (this.predepartures && this.predepartures.length > 0) {
        originalChecked = this.predepartures.flatMap((item) =>
          item.children.filter((child) => child.ticked)
        );
      }
      if (this.items && this.items.length > 0) {
        newChecked = this.items.flatMap((item) => item.children.filter((child) => child.ticked));
      }
      if (originalChecked && newChecked && originalChecked.length !== newChecked.length) {
        valuesChanged = true;
      }
      return valuesChanged;
    },
    async exportPredeparture() {
      this.predepartureDownloadActionInProgress = true;
      this.predepartureExportActionCompleted = false;

      const excursionMetadata = JSON.parse(JSON.stringify(this.$store.state.common.excursionDc));

      const { query } = this.$route;
      const clientLogoEnabled = !(query && query.referrer && query.referrer === 'pcg');

      const excursionCommonAdapter = new this.$app.excursionCommon.Adapter({
        store: this.$store,
        app: this.$app
      });

      const queryParam = {
        params: {
          query,
          payload: {
            'client-logo-enabled': clientLogoEnabled
          }
        },
        excursionId: excursionMetadata.id
      };

      const error = await excursionCommonAdapter.downloadPredeparturePdf(
        queryParam,
        this.$app.deviceDetector
      );

      if (!error) {
        this.dialog = false;
      }

      this.predepartureDownloadActionInProgress = false;
      this.predepartureExportActionCompleted = true;
    },
    clickItemTitle(item) {
      const localItem = item;
      localItem.ticked = !localItem.ticked;
      this.$forceUpdate();
    },
    async save(callback) {
      this.predepartureSaveActionInProgress = true;
      this.$store.commit(types.COMMON_SET_PREDEPARTURE, this.items);
      try {
        const excursionId = this.$store.state.common.excursionDc.id;
        const queryParam = {
          params: {
            query: this.$route.query,
            payload: {
              predepartures: this.items,
              updateSignature: this.$store.state.common.excursionDc.updateSignature
            }
          },
          excursionId
        };

        const that = this;
        const argHash = {
          stbApiAdapter: this.$app.stbApiAdapter,
          store: this.$store,
          queryParam
        };
        await this.$store.dispatch('common/updateExcursionPredepartureStrategies', argHash);
        that.predepartureSaveActionInProgress = false;
        if (callback && typeof callback === 'function') {
          callback();
        } else {
          that.predepartureDownloadActionInProgress = false;
          that.predepartureExportActionCompleted = true;
          this.close();
        }
      } catch (e) {
        this.predepartureDownloadActionInProgress = false;
        this.predepartureExportActionCompleted = true;
        this.predepartureSaveActionInProgress = false;
      }
    },
    async exportContent(presaveConfirmed) {
      if (presaveConfirmed) {
        this.predepartureDownloadActionInProgress = true;
        this.predepartureExportActionCompleted = false;
        await this.save(async () => {
          await this.exportPredeparture();
        });
      } else {
        await this.exportPredeparture();
      }
    },
    getPreCheckLabelId(item, index, section) {
      let preCheckId = '';
      if (item && item.item) {
        preCheckId = `view-predeparture-checklist--${String(section).replace(
          /[* ]/g,
          ''
        )}--label-${item.item.replace(/\s*/g, '')}-${index}`;
      } else {
        preCheckId = `view-predeparture-checklist--${String(section).replace(
          /[* ]/g,
          ''
        )}--label-${index}`;
      }
      return preCheckId;
    },
    getPreCheckId(item, index, section) {
      let preCheckId = '';
      if (item && item.item) {
        preCheckId = `view-predeparture-checklist--${String(section).replace(
          /[* ]/g,
          ''
        )}--pre-check-${item.item.replace(/\s*/g, '')}-${index}`;
      } else {
        preCheckId = `view-predeparture-checklist--${String(section).replace(
          /[* ]/g,
          ''
        )}--pre-check-${index}`;
      }
      return preCheckId;
    },
    checklistChildrenDisplayed() {
      const item = this.items[0];
      let displayed = false;
      if (item && item.children) {
        displayed = true;
      }
      return displayed;
    },
    onPredepartureDialogChange(boolOpen) {
      if (!boolOpen) {
        this.close();
      }
    },
    onConfirmExportSave(presaveConfirmed) {
      this.presaveConfirmationDialogDisplayed = false;
      this.exportContent(presaveConfirmed);
    }
  },
  mounted() {
    this.items = [];
  }
};
</script>
<style scoped>
.pre-check {
  margin-top: 0 !important;
  flex: unset;
  width: 30px;
}

.pre-check#preCheck .v-input--selection-controls {
  margin-top: unset;
}

.pre-check-desc {
  line-height: 34px;
  cursor: pointer;
  margin-bottom: 16px;
}

.predeparture-check-list {
  padding: 0px;
}

.predeparture-check-list .subhealder {
  margin-left: -15px;
  font-size: unset;
}

.predeparture-check-list .checklist-item-comment-content-container {
  padding-left: 0px;
  padding-top: 10px;
}

.predeparture-check-list .checklist-item-comment-button-container {
  padding-left: 0px;
}

.pre-checklist-item-comment {
  padding-left: 15px;
}
</style>
