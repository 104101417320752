<template>
  <v-layout class="export-excursion-policy">
    <v-btn
      @click.native="displayExcursionPolicyExportModalWithPresaveConfirmation()"
      class="normal-btn white--text primary"
      id="btnExportExcursionPolicy"
      :disabled="this.excursionPolicyExportActionEnabled"
    >
      <v-icon class="excursion-policy-export-icon">format_indent_increase</v-icon>
      Export Checklists / Policies
    </v-btn>
    <cs-form-dialog
      v-model="excursionPolicyExportModalDisplayed"
      id="dlg-export-excursion-policy"
      bodyId="dlg-export-excursion-policy-body"
      :heading="dialogHeading"
      :primaryAction="{
        label: 'Export',
        eventName: 'primary-click',
        disabled: disableExportExcursion || policyDownloadActionInProgress,
        loading: policyDownloadActionInProgress
      }"
      @primary-click="downloadExcursionPoliciesPdf"
      :secondaryAction1="{
        label: 'Cancel',
        eventName: 'secondary1-click'
      }"
      @secondary1-click="excursionPolicyExportModalDisplayed = false"
    >
      <template v-slot:cs-form-dialog-content>
        <v-container class="export-excursion-policy-content">
          <div v-if="excursionPolicyViewActionEnabled">
            <v-flex>
              <strong>Excursion Policies</strong>
            </v-flex>
            <v-layout class="selected-risk-layout">
              <v-flex xs1>
                <v-checkbox
                  id="export-excursion-policy--export-excursion-policy"
                  @change="changeMasterExcursionPolicy()"
                  v-model="excursionPolicyExportChecked"
                  class="excursion-policy-item-checkbox"
                ></v-checkbox>
              </v-flex>
              <v-flex xs11 class="excursion-policy-item">
                <label for="export-excursion-policy--export-excursion-policy">
                  Export Excursion Policy
                </label>
              </v-flex>
              <view-policy
                :showViewPolicyLabel="true"
                :masterPolicyExported="false"
                :excursionPolicyExported="true"
              />
            </v-layout>
            <v-layout class="selected-risk-layout">
              <v-flex xs1>
                <v-checkbox
                  id="export-excursion-policy--export-master-excursion-policy"
                  @change.native="changeExcursionPolicy()"
                  v-model="masterPolicyExportChecked"
                  class="excursion-policy-item-checkbox"
                  testing-id="masterExcursionPolicy"
                ></v-checkbox>
              </v-flex>
              <v-flex xs11 class="excursion-policy-item">
                <label for="export-excursion-policy--export-master-excursion-policy">
                  Export Master Excursion Policy
                </label>
              </v-flex>
              <view-policy
                :showViewPolicyLabel="true"
                :masterPolicyExported="true"
                :excursionPolicyExported="false"
              />
            </v-layout>
            <div style="height: 10px"></div>
            <hr style="margin-bottom: unset" />
            <v-layout class="selected-risk-layout" v-for="item in items" :key="item.id">
              <v-flex xs1>
                <v-checkbox
                  :id="getChecklistItemId(item)"
                  v-model="item.ticked"
                  @change="checkRiskItem()"
                  :disabled="!item.isActive"
                  class="excursion-policy-item-checkbox"
                ></v-checkbox>
              </v-flex>
              <v-flex
                xs11
                :id="getCheckRiskItemCheckboxLabel(item)"
                :class="
                  item.isActive
                    ? 'excursion-policy-item selected-risk'
                    : 'excursion-policy-item unselected-risk'
                "
                @click="checkRiskItemByTitle(item)"
                >{{ item.name }}</v-flex
              >
              <view-policy
                :categoryName="item.name"
                :elementDisabled="!item.isActive"
                :showViewPolicyLabel="true"
              />
            </v-layout>
          </div>
          <v-flex style="margin-top: 20px">
            <strong>Excursion Checklists</strong>
          </v-flex>
          <v-layout class="selected-risk-layout">
            <v-flex xs1>
              <v-checkbox
                id="export-excursion-policy--mandatory-excursion-checklists"
                v-model="mandatoryExcursionChecklistsExportChecked"
                class="excursion-policy-item-checkbox"
              ></v-checkbox>
            </v-flex>
            <v-flex
              xs11
              id="export-excursion-policy--mandatory-excursion-checklists--label"
              class="excursion-policy-item"
              @click="changeMandatoryExcursionChecklistsTitle()"
              >Mandatory Excursion Checklists</v-flex
            >
            <view-all-mandatory-checklist></view-all-mandatory-checklist>
          </v-layout>
          <v-layout class="selected-risk-layout">
            <v-flex xs1>
              <v-checkbox
                id="export-excursion-policy--risk-category-checklists"
                v-model="categoryChecklistsExported"
                :disabled="checklistViewActionEnabled"
                class="excursion-policy-item-checkbox"
              ></v-checkbox>
            </v-flex>
            <v-flex
              xs11
              id="export-excursion-policy--risk-category-checklists-label"
              class="excursion-policy-item"
              @click="changeCategoryChecklistsTitle()"
              >Risk Category Checklists</v-flex
            >
            <complete-checklist
              :checklistReadOnly="true"
              :checklistViewModeEnabled="true"
              :checklistViewActionEnabled="checklistViewActionEnabled"
            ></complete-checklist>
          </v-layout>
          <v-layout class="selected-risk-layout" v-for="item in activityItems" :key="item.name">
            <v-flex xs1>
              <v-checkbox
                :id="getChecklistItemId(item)"
                v-model="item.ticked"
                class="excursion-policy-item-checkbox"
              ></v-checkbox>
            </v-flex>
            <v-flex
              xs11
              :id="getPolicyRiskCategoryChecklistItemId(item)"
              class="excursion-policy-item selected-risk"
              @click="checkChecklistItemByTitle(item)"
              >{{ item.name }}</v-flex
            >
            <complete-checklist
              :categoryName="item.activityClassesName"
              :riskTypeName="item.riskTypeName"
              :riskClassName="item.riskClassName"
              :checklistViewModeEnabled="true"
            ></complete-checklist>
          </v-layout>
        </v-container>
      </template>
    </cs-form-dialog>
    <div>
      <cs-form-dialog
        v-model="presaveConfirmationDialogDisplayed"
        id="dlg-export-excursion-policy-presave-confirm"
        bodyId="dlg-export-excursion-policy-presave-confirm-body"
        heading="Export Excursion Policy"
        :primaryAction="{
          label: 'Save',
          eventName: 'primary-click',
          disabled: excursionSaveActionInProgress,
          loading: excursionSaveActionInProgress
        }"
        @primary-click="saveExcursion"
        :secondaryAction1="{
          label: 'Cancel',
          eventName: 'secondary1-click'
        }"
        @secondary1-click="
          presaveConfirmationDialogDisplayed = false;
          excursionSaveActionInProgress = false;
        "
      >
        <template v-slot:cs-form-dialog-content> You need to Save before you can export. </template>
      </cs-form-dialog>
    </div>
  </v-layout>
</template>

<script>
export default {
  props: [
    'excursionPolicyExportActionEnabled',
    'valuesChanged',
    'submitExcursion',
    'excursionBuilderViewDisplayed'
  ],
  data() {
    return {
      excursionPolicyExportModalDisplayed: false,
      excursionPolicyExportChecked: false,
      masterPolicyExportChecked: false,
      items: [],
      policyDownloadActionInProgress: false,
      excursionSaveActionInProgress: false,
      excludeRiskCategory: 'Outdoor Education Providers',
      mandatoryExcursionChecklistsExportChecked: false,
      activityItems: [],
      excursionPolicyViewActionEnabled: false,
      presaveConfirmationDialogDisplayed: false,
      categoryChecklistsExported: false
    };
  },
  computed: {
    disableExportExcursion() {
      let disabled = true;
      if (this.masterPolicyExportChecked || this.excursionPolicyExportChecked) {
        disabled = false;
      } else {
        this.items.forEach((item) => {
          if (item.ticked) {
            disabled = false;
          }
        });

        if (this.mandatoryExcursionChecklistsExportChecked) {
          disabled = false;
        }

        if (this.categoryChecklistsExported) {
          disabled = false;
        }

        this.activityItems.forEach((item) => {
          if (item.ticked) {
            disabled = false;
          }
        });
      }
      return disabled;
    },
    checklistViewActionEnabled() {
      let disabled = true;
      const selectedExcursion = this.$store.state.common.excursionDc;

      const { categories } = selectedExcursion;
      if (categories && categories.length > 0) {
        const categoryComplete = selectedExcursion.categories.filter(
          (c) =>
            c.name.toLowerCase() !== 'activities' &&
            c.CompleteChecklist &&
            c.CompleteChecklist.length > 0
        );

        disabled = !(categoryComplete && categoryComplete.length > 0);
      }

      return disabled;
    },
    dialogHeading() {
      if (this.excursionPolicyViewActionEnabled) {
        return 'Excursion Policies and Checklists';
      }
      return 'Export Excursion Policy';
    }
  },
  created() {
    this.generateRiskData();
  },
  methods: {
    getPolicyRiskCategoryChecklistItemId(item) {
      return `export-excursion-policy--risk-category-checklist-item-checkbox--label--${item.name.replace(
        /\s*/g,
        ''
      )}`;
    },
    getCheckRiskItemCheckboxLabel(item) {
      return `export-excursion-policy--risk-item-checkbox--label--${item.name.replace(/\s*/g, '')}`;
    },
    getChecklistItemId(item) {
      return `export-excursion-policy--${item.name.replace(/\s*/g, '')}`;
    },
    generateRiskData() {
      this.items = [];
      this.excursionPolicyViewActionEnabled =
        this.$store.state.common.excursionPolicyViewActionEnabled;
      this.categoryChecklistsExported = false;
      this.excursionPolicyChecked = false;
      this.mandatoryExcursionChecklistsExportChecked = false;
      this.masterPolicyExportChecked = false;
      this.excursionPolicyExportChecked = false;
      const allRisk = this.$store.state.common.allRisks;
      const selectedRisk = this.$store.state.common.excursionDc.categories;
      this.activityItems = [];

      if (allRisk && allRisk.length > 0) {
        allRisk.forEach((item) => {
          const riskTreeItem = {
            id: item.id,
            name: item.name,
            ticked: false,
            isActive: false
          };

          if (selectedRisk && selectedRisk.length > 0) {
            const existRisk = selectedRisk.find((sk) => {
              return `${sk.name}` === `${item.name}`;
            });

            if (
              existRisk &&
              existRisk.name !== this.excludeRiskCategory &&
              existRisk.types &&
              existRisk.types.length > 0
            ) {
              existRisk.types.forEach((type) => {
                if (type && type.classes && type.classes.length > 0) {
                  riskTreeItem.isActive = true;

                  if (item.name === 'Activities') {
                    this.generateActivityData(type.classes, type.name, item.name);
                  }
                }
              });
            } else if (existRisk && existRisk.name === this.excludeRiskCategory) {
              riskTreeItem.isActive = true;
            }
          }

          this.items.push(riskTreeItem);
        });
      }
    },
    generateActivityData(classes, riskTypeName, activityClassesName) {
      classes.forEach((item) => {
        this.activityItems.push({
          name: item.name,
          riskTypeName,
          riskClassName: item.name,
          activityClassesName,
          ticked: false,
          checklist: item.CompleteChecklist
        });
      });
    },
    checkChecklistItemByTitle(item) {
      if (item.checklist) {
        // eslint-disable-next-line no-param-reassign
        item.ticked = !item.ticked;
      }
    },
    async downloadExcursionPoliciesPdf() {
      this.policyDownloadActionInProgress = true;

      const exportedCategoryChecklists = [];
      if (this.activityItems && this.activityItems.length > 0) {
        this.activityItems.forEach((item) => {
          if (item.ticked) {
            exportedCategoryChecklists.push(item.name);
          }
        });
      }

      const { query } = this.$route;
      const clientLogoEnabled = !(query && query.referrer && query.referrer === 'pcg');

      const params = {
        masterPolicyExported: this.masterPolicyExportChecked,
        excursionPolicyExported: this.excursionPolicyExportChecked,
        exportedItems: this.items,
        clientLogoEnabled,
        mandatoryChecklistsExported: this.mandatoryExcursionChecklistsExportChecked,
        exportedCategoryChecklists,
        categoryChecklistsExported: this.categoryChecklistsExported
      };

      const excursionCommonAdapter = new this.$app.excursionCommon.Adapter({
        store: this.$store,
        app: this.$app
      });

      await excursionCommonAdapter.downloadExcursionRiskContentsPdf(
        this.$route.query,
        params,
        this.$app.deviceDetector
      );

      this.policyDownloadActionInProgress = false;
      this.excursionPolicyExportModalDisplayed = false;
      this.categoryChecklistsExported = false;
    },
    checkRiskItem() {
      this.masterPolicyExportChecked = false;
      this.excursionPolicyExportChecked = false;
    },
    checkRiskItemByTitle(item) {
      if (!item.isActive) {
        return;
      }
      this.masterPolicyExportChecked = false;
      this.excursionPolicyExportChecked = false;
      // eslint-disable-next-line no-param-reassign
      item.ticked = !item.ticked;
    },
    changeExcursionPolicy() {
      if (this.masterPolicyExportChecked && this.items && this.items.length) {
        this.excursionPolicyExportChecked = false;
      }
      this.clearLinesChecking();
    },
    changeMasterExcursionPolicy() {
      if (this.excursionPolicyExportChecked && this.items && this.items.length) {
        this.masterPolicyExportChecked = false;
      }
      this.clearLinesChecking();
    },
    changeMandatoryExcursionChecklistsTitle() {
      this.mandatoryExcursionChecklistsExportChecked =
        !this.mandatoryExcursionChecklistsExportChecked;
    },
    changeCategoryChecklistsTitle() {
      if (!this.checklistViewActionEnabled) {
        this.categoryChecklistsExported = !this.categoryChecklistsExported;
        this.excursionPolicyChecked = false;
      }
    },
    clearLinesChecking() {
      if (this.items && this.items.length > 0) {
        this.items.forEach((item) => {
          // eslint-disable-next-line no-param-reassign
          item.ticked = false;
        });
      }
    },
    saveExcursion() {
      this.excursionSaveActionInProgress = true;
      if (this.excursionBuilderViewDisplayed) {
        this.submitExcursion(null, false, () => {
          this.excursionSaveActionInProgress = false;
          this.presaveConfirmationDialogDisplayed = false;
          this.excursionPolicyExportModalDisplayed = true;
          this.generateRiskData();
        });
      } else {
        this.submitExcursion(
          null,
          false,
          () => {
            this.excursionSaveActionInProgress = false;
            this.presaveConfirmationDialogDisplayed = false;
            this.excursionPolicyExportModalDisplayed = true;
            this.generateRiskData();
          },
          'downloadExcursionPolicy'
        );
      }
    },
    displayExcursionPolicyExportModalWithPresaveConfirmation() {
      if (
        this.submitExcursion &&
        typeof this.submitExcursion === 'function' &&
        this.valuesChanged
      ) {
        this.presaveConfirmationDialogDisplayed = true;
      } else {
        this.excursionPolicyExportModalDisplayed = true;
        this.generateRiskData();
      }
    }
  }
};
</script>
<style scoped>
.export-excursion-policy-content {
  padding: 0px;
  margin: 0px 0px;
}

.selected-risk-layout {
  max-height: 400px;
  height: 40px;
}

.unselected-risk {
  color: gray;
}

.selected-risk {
  color: #000;
}

.container.grid-list-md .layout .flex {
  padding: 0px;
}

.excursion-policy-item {
  line-height: 60px;
  cursor: pointer;
}

.excursion-policy-item:hover {
  cursor: pointer;
}

.export-excursion-policy-content .v-btn {
  padding: 0px;
  margin: 11px 0px;
}

.excursion-policy-export-icon {
  font-size: 16px;
  margin-right: 5px;
}
</style>
