var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"complete-check-list"},[(!_vm.checklistViewModeEnabled)?_c('v-btn',{staticClass:"normal-btn mandatory-checklist-button",attrs:{"disabled":_vm.elementDisabled,"loading":_vm.checklistModalDisplayed,"depressed":"","id":_vm.completeChecklistBtnId},nativeOn:{"click":function($event){return _vm.getData()}}},[(!_vm.getChecklistFetchActionInProgress())?_c('v-icon',{class:_vm.checklistCompletionIndicatorEnabled ? 'complete-green' : 'complete-green incompleted',style:({
        color: _vm.checklistCompletionIndicatorEnabled ? '#28a745' : 'lightgray'
      })},[_vm._v(_vm._s(_vm.checklistCommentIconDisplayed ? 'fa fa-comment' : 'fa fa-check'))]):_vm._e(),(_vm.getChecklistFetchActionInProgress())?_c('v-icon',[_vm._v("fa fa-spinner fa-pulse")]):_vm._e(),_vm._v(" Complete Checklist ")],1):_vm._e(),(_vm.checklistViewModeEnabled)?_c('cs-button',{attrs:{"id":_vm.completeChecklistBtnId,"label":"View Checklist","disabled":_vm.checklistModalDisplayed || _vm.checklistViewActionEnabled,"loading":_vm.checklistModalDisplayed},on:{"click":function($event){$event.stopPropagation();return _vm.getData()}}}):_vm._e(),_c('cs-form-dialog',{attrs:{"id":"dlg-complete-check-list","bodyId":_vm.getBodyId,"heading":"Complete Checklist","primaryAction":{
      label: 'Save',
      eventName: 'primary-click',
      disabled: _vm.checklistViewModeEnabled || _vm.archivedExcursionDetected
    },"secondaryAction1":{
      label: 'Close',
      eventName: 'secondary1-click'
    }},on:{"primary-click":function($event){return _vm.save(true)},"secondary1-click":_vm.close},scopedSlots:_vm._u([{key:"cs-form-dialog-content",fn:function(){return [_c('div',{staticClass:"checklist-content"},[(!_vm.checklist || _vm.checklist.length <= 0)?_c('div',{staticClass:"loading-div"},[_c('v-progress-circular',{staticClass:"loading-img",attrs:{"size":70,"color":"rgb(29, 175, 236)","indeterminate":""}})],1):_vm._e(),_vm._l((_vm.checklist),function(section,index){return _c('v-list',{key:index},[(_vm.checklistReadOnly)?_c('v-subheader',[_c('h1',[_vm._v(_vm._s(section.name))])]):_vm._e(),(!_vm.checklistReadOnly)?_c('v-subheader',[_c('h1',[_vm._v(_vm._s(_vm.riskClassName ? _vm.riskClassName : _vm.categoryName))])]):_vm._e(),_vm._l((_vm.getItemsByTitle(section.checklist)),function(item,index){return _c('v-list',{key:index},[('form-group' === item.contentType)?_c('div',[_c('v-subheader',{staticClass:"checklist-item-subtitle"},[_c('vue-markdown',{attrs:{"source":item.label,"typographer":false,"id":_vm.getChecklistTitleId(item)}})],1),_vm._l((item.children),function(option,index){return _c('div',{key:index,attrs:{"id":_vm.getChecklistItemId(item)}},[_c('v-list-item',[('checkbox' === option.contentType)?_c('div',{staticStyle:{"display":"flex"}},[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"color":"#c80063","id":("complete-checklist--" + (String(item.label)
                          .replaceAll(' ', '-')
                          .replaceAll('#', '')) + "--checkbox--" + index),"disabled":!_vm.formControlEnabled},model:{value:(option.ticked),callback:function ($$v) {_vm.$set(option, "ticked", $$v)},expression:"option.ticked"}})],1),_c('v-list-item-content',[_c('vue-markdown',{attrs:{"source":option.label,"typographer":false}})],1)],1):_vm._e(),('form-group' === option.contentType)?_c('div',{staticStyle:{"margin-left":"-15px"}},[_c('v-subheader',[_c('vue-markdown',{attrs:{"source":option.label,"typographer":false}})],1),_vm._l((option.children),function(childOption,index){return _c('div',{key:index},[_c('v-list-item',[('checkbox' === childOption.contentType)?_c('v-list-item-action',[_c('v-checkbox',{attrs:{"color":"#c80063","id":("complete-checklist--" + (String(item.label)
                              .replaceAll(' ', '-')
                              .replaceAll('#', '')) + "--" + (String(option.label)
                              .replaceAll(' ', '-')
                              .replaceAll('#', '')) + "--form-group-content-type-checkbox--" + index),"disabled":!_vm.formControlEnabled},model:{value:(childOption.ticked),callback:function ($$v) {_vm.$set(childOption, "ticked", $$v)},expression:"childOption.ticked"}})],1):_vm._e(),_c('v-list-item-content',[_c('vue-markdown',{attrs:{"source":childOption.label,"typographer":false}})],1)],1),_c('checklist-item-comment',{attrs:{"checklistItem":childOption,"commentBtnId":("complete-checklist--" + (String(item.label)
                          .replaceAll(' ', '-')
                          .replaceAll('#', '')) + "--" + (String(option.label)
                          .replaceAll(' ', '-')
                          .replaceAll('#', '')) + "--form-group-comment--" + index)}})],1)})],2):_vm._e()]),('checkbox' === option.contentType)?_c('checklist-item-comment',{attrs:{"checklistItem":option,"commentBtnId":("complete-checklist--" + (String(
                    _vm.riskClassName ? _vm.riskClassName : _vm.categoryName
                  ).replaceAll(' ', '-')) + "--" + (String(item.label)
                    .replaceAll(' ', '-')
                    .replaceAll('#', '')) + "--content-type-comment--" + index)}}):_vm._e()],1)})],2):_c('div',[(item.children != undefined)?_c('div',_vm._l((item.children),function(checklistItem,index){return _c('v-list-item-content',{key:index},[('form-group' === checklistItem.contentType)?_c('div',[_c('v-subheader',{staticClass:"checklist-item-subtitle"},[_c('vue-markdown',{attrs:{"source":checklistItem.label,"typographer":false,"id":_vm.getChecklistTitleId(checklistItem)}})],1),_vm._l((checklistItem.children),function(option,index){return _c('div',{key:index,attrs:{"id":_vm.getChecklistItemId(checklistItem)}},[_c('v-list-item',[('checkbox' === option.contentType)?_c('v-list-item-action',[_c('v-checkbox',{attrs:{"color":"#c80063","id":("complete-checklist--" + (String(checklistItem.label)
                              .replaceAll(' ', '-')
                              .replaceAll('#', '')) + "--form-group-checkbox--" + index),"disabled":!_vm.formControlEnabled},model:{value:(option.ticked),callback:function ($$v) {_vm.$set(option, "ticked", $$v)},expression:"option.ticked"}})],1):_vm._e(),_c('v-list-item-content',[_c('vue-markdown',{attrs:{"source":option.label,"typographer":false}})],1)],1),_c('checklist-item-comment',{attrs:{"checklistItem":option,"commentBtnId":("complete-checklist--" + (String(checklistItem.label)
                          .replaceAll(' ', '-')
                          .replaceAll('#', '')) + "--form-group-checkbox-comment--" + index)}})],1)})],2):_vm._e()])}),1):_c('div',[_c('v-subheader',[_c('vue-markdown',{attrs:{"source":item.label,"typographer":false}})],1),_c('checklist-item-comment',{attrs:{"checklistItem":item,"commentBtnId":("complete-checklist--" + (String(item.label)
                    .replaceAll(' ', '-')
                    .replaceAll('#', '')) + "--" + (String(
                    _vm.riskClassName ? _vm.riskClassName : _vm.categoryName
                  ).replaceAll(' ', '-')) + "--comment--" + index)}})],1)])])})],2)})],2)]},proxy:true}]),model:{value:(_vm.checklistModalDisplayed),callback:function ($$v) {_vm.checklistModalDisplayed=$$v},expression:"checklistModalDisplayed"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }