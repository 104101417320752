var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.riskCategories),function(category,index){return _c('div',{key:index,staticClass:"category-div"},[(_vm.categorySectionDisplayed(category.name))?_c('div',[_c('div',[_c('v-layout',{staticStyle:{"display":"flex"},attrs:{"align-center":"","justify-space-between":""}},[_c('div',[_c('v-layout',{staticClass:"category-container",attrs:{"align-center":"","justify-space-between":""}},[_c('v-icon',{staticClass:"icon",attrs:{"id":_vm.getToggleCategoryIconId(category.name)},on:{"click":function($event){$event.stopPropagation();return _vm.toggleCategory(index)}}},[_vm._v(" "+_vm._s(!category.displayed ? 'keyboard_arrow_left' : 'keyboard_arrow_down')+" ")]),_c('div',{staticClass:"name",attrs:{"id":_vm.getToggleCategoryId(category.name)},on:{"click":function($event){$event.stopPropagation();return _vm.toggleCategory(index)}}},[_vm._v(" "+_vm._s(category.name)+" ")]),(0 > _vm.excludedRiskCategories.indexOf(category.name))?_c('div',{staticClass:"quantity ml-3"},[_vm._v(" "+_vm._s(_vm.typesCount(category.name))+" Types ")]):_vm._e(),('Activities' === category.name)?_c('div',{staticClass:"quantity ml-3"},[_vm._v(" "+_vm._s(_vm.activityTypesCount)+" Types ")]):_vm._e(),(_vm.highRiskCategoryName !== category.name)?_c('div',{staticClass:"quantity ml-3"},[_vm._v(" "+_vm._s(_vm.approvedProvidersCount(category.name))+" Providers ")]):_vm._e()],1)],1),_c('div',{staticStyle:{"display":"flex"}},[('Activities' !== category.name)?_c('complete-checklist',{attrs:{"categoryName":category.name,"checklistViewModeEnabled":false,"formControlEnabled":_vm.formControlEnabled,"associatedChecklistCompleted":_vm.getTicked(category),"elementDisabled":(0 >= _vm.typesCount(category.name) && category.name !== _vm.excludedRiskCategories[1]) ||
                (0 >= _vm.approvedProvidersCount(category.name) &&
                  category.name === _vm.excludedRiskCategories[1]) ||
                (_vm.checklistFetchActionInProgress &&
                  _vm.checklistFetchActionCategoryName === category.name),"checklistFetchActionCategoryName":_vm.checklistFetchActionCategoryName,"checklistFetchActionInProgress":_vm.checklistFetchActionInProgress,"saveDraft":_vm.saveDraft}}):_vm._e(),(
                (_vm.excursionPolicyViewActionEnabled && 'Activities' !== category.name) ||
                'Outdoor Education Providers' === category.name
              )?_c('view-policy',{attrs:{"categoryName":category.name,"elementDisabled":(0 >= _vm.typesCount(category.name) && category.name !== _vm.excludedRiskCategories[1]) ||
                (0 >= _vm.approvedProvidersCount(category.name) &&
                  category.name === _vm.excludedRiskCategories[1])}}):_vm._e(),(_vm.excursionPolicyViewActionEnabled && 'Activities' === category.name)?_c('view-policy',{attrs:{"categoryName":category.name,"elementDisabled":0 >= _vm.activityTypesCount,"activitySupervisorScrollDisplayed":false}}):_vm._e()],1)])],1),_c('div',{attrs:{"hidden":!category.displayed}},[_c('v-layout',[(
              0 > _vm.excludedRiskCategories.indexOf(category.name) &&
              category.name !== _vm.highRiskCategoryName
            )?_c('select-control',{attrs:{"background-color":"#EEEEEE","name":category.name,"id":_vm.getTypeControlId(category.name),"formControlEnabled":_vm.formControlEnabled},on:{"changeRiskCategoryDone":_vm.changeRiskCategory}}):_vm._e(),(category.name === _vm.highRiskCategoryName)?_c('high-risk',{attrs:{"background-color":"#EEEEEE","name":category.name,"id":_vm.getTypeControlId(category.name),"formControlEnabled":_vm.formControlEnabled},on:{"changeRiskCategoryDone":_vm.changeRiskCategory}}):_vm._e(),('Activities' === category.name)?_c('activity-risk',{attrs:{"formControlEnabled":_vm.formControlEnabled,"checklistAndPolicyDisplayed":true,"saveDraft":_vm.saveDraft}}):_vm._e()],1),_c('v-layout',{staticClass:"sub-category-container"},[(0 < _vm.getProvidersByCategoryCount(category.name))?_c('v-flex',{attrs:{"xs12":"","providers":"","label-padding":""}},[_vm._v(" Providers "),_c('v-tooltip',{attrs:{"right":"","color":"#ffffff"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var tooltip = ref.on;
return [(_vm.hasNegativeProviderAnswer(category.name))?_c('v-btn',_vm._g({staticClass:"provider-tooltip-icon",attrs:{"id":_vm.getProviderTooltipBtnId(category.name),"icon":""}},Object.assign({}, tooltip)),[_c('i',{staticClass:"fas fa-info-circle"})]):_vm._e()]}}],null,true)},[_c('span',{staticClass:"provider-tooltip-msg"},[_vm._v(_vm._s(_vm.negativeProviderAnswerHint))])])],1):_vm._e()],1),(0 < _vm.getProvidersByCategoryCount(category.name))?_c('v-layout',{staticClass:"move-top-risk-title"},[_c('providers',{attrs:{"categoryName":category.name,"formControlEnabled":_vm.formControlEnabled}})],1):_vm._e(),(_vm.excursionPersonResponsibleEnabled)?_c('v-flex',{staticClass:"text-field-title",style:({
            'padding-top': 0 >= _vm.getProvidersByCategoryCount(category.name) ? '8px' : '0px'
          }),attrs:{"id":("risk-category-txt-people-responsible-" + (_vm.getTypeControlId(category.name))),"xs12":""}},[_vm._v("People or Providers Responsible")]):_vm._e(),(_vm.excursionPersonResponsibleEnabled)?_c('v-flex',{staticClass:"excursion-detail-text",staticStyle:{"width":"calc(100% - 4px)"},attrs:{"xs12":""}},[_c('v-textarea',{ref:"items",refInFor:true,staticClass:"name-textbox excursion-input excursion-people-responsible-textarea",attrs:{"solo":"","value":category.personResponsible,"height":60,"no-resize":true,"placeholder":_vm.personResponsiblePlaceholder,"disabled":!_vm.formControlEnabled ||
              (0 >= _vm.approvedProvidersCount(category.name) &&
                'Outdoor Education Providers' === category.name),"id":("personResponsible-" + (_vm.getTypeControlId(category.name)))},on:{"input":function($event){return _vm.updatePersonResponsible(category, $event)}}}),(_vm.checkIfAnyElementVerticalScrollEnabled(category.name))?_c('div',{staticClass:"show-more-link",attrs:{"id":("risk-category--person-responsible-show-more--button-" + (_vm.getTypeControlId(
              category.name
            )))},on:{"click":function($event){return _vm.displayDetailsDialog('People or Providers Responsible', category.personResponsible)}}},[_vm._v(" Show more ")]):_vm._e()],1):_vm._e(),(_vm.additionalRiskControlMitigationStrategiesEnabled)?_c('v-flex',{staticClass:"text-field-title",style:({
            'padding-top': 0 >= _vm.getProvidersByCategoryCount(category.name) ? '8px' : '0px'
          }),attrs:{"xs12":""}},[_vm._v("Additional Risk Control Mitigation Strategies ")]):_vm._e(),(_vm.additionalRiskControlMitigationStrategiesEnabled)?_c('v-flex',{staticClass:"excursion-detail-text",staticStyle:{"width":"calc(100% - 4px)"},attrs:{"xs12":""}},[_c('v-textarea',{ref:"items",refInFor:true,staticClass:"name-textbox excursion-input category-textarea",attrs:{"solo":"","value":category.additionalRiskControlMitigationStrategies,"height":60,"no-resize":true,"placeholder":_vm.additionalRiskControlPlaceholder,"disabled":!_vm.formControlEnabled ||
              (0 >= _vm.approvedProvidersCount(category.name) &&
                'Outdoor Education Providers' === category.name),"id":("additionalRiskControl-" + (_vm.getTypeControlId(category.name)))},on:{"input":function($event){return _vm.updateAdditionalRiskControlMitigationStrategies(category, $event)}}}),(_vm.checkIfAnyElementVerticalScrollEnabled(category.name))?_c('div',{staticClass:"show-more-link",attrs:{"id":("risk-category--addition-risk-control-show-more--button-" + (_vm.getTypeControlId(
              category.name
            )))},on:{"click":function($event){return _vm.displayDetailsDialog(
                'Additional Risk Control Mitigation Strategies',
                category.additionalRiskControlMitigationStrategies
              )}}},[_vm._v(" Show more ")]):_vm._e()],1):_vm._e(),(
            0 >= _vm.getProvidersByCategoryCount(category.name) &&
            category.name !== _vm.highRiskCategoryName
          )?_c('div',{staticStyle:{"padding-bottom":"18px"}}):_vm._e()],1)]):_vm._e()])}),((!_vm.riskCategories || 0 >= _vm.riskCategories.length) && !_vm.archivedExcursionDetected)?_c('div',{staticClass:"excursion-waiting"},[_c('v-progress-circular',{staticClass:"loading-img",attrs:{"size":30,"color":"rgb(29, 175, 236)","indeterminate":""}})],1):_vm._e(),_c('div',[_c('cs-form-dialog',{attrs:{"id":"dlg-risk-category--generic","bodyId":"dlg-risk-category--generic-body","heading":"People or Providers Responsible","primaryAction":{
        label: 'OK',
        eventName: 'primary-click'
      }},on:{"primary-click":function($event){_vm.genericDialogDisplayed = false}},scopedSlots:_vm._u([{key:"cs-form-dialog-content",fn:function(){return [_vm._v(" "+_vm._s(_vm.genericDialogMessage)+" ")]},proxy:true}]),model:{value:(_vm.genericDialogDisplayed),callback:function ($$v) {_vm.genericDialogDisplayed=$$v},expression:"genericDialogDisplayed"}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }