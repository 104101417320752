var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"settings-container",attrs:{"grid-list-md":"","text-xs-center":""}},[_c('div',{staticClass:"client-admin-groups-btn-container"},[_c('v-btn',{attrs:{"color":"primary","id":"clientAdminGroupsDownload"},on:{"click":_vm.handleDownloadGroupButtonClick}},[_c('span',[_vm._v("Download Groups")])]),_c('v-btn',{attrs:{"color":"primary","id":"clientAdminGroupsUpload"},on:{"click":function($event){_vm.groupImportModalDisplayed = true}}},[_c('span',[_vm._v("Upload Groups")])])],1),_c('div',{staticClass:"client-admin-groups-message-container"},[(!_vm.hasSchoolGroups)?_c('span',[_vm._v("You have not populated your school groups for PlanCheckGo")]):_vm._e(),(_vm.hasSchoolGroups)?_c('span',[_vm._v("Your school groups have been set up for PlanCheckGo")]):_vm._e()]),_c('cs-form-dialog',{attrs:{"id":"dlg-import-groups","heading":"Upload Groups","primaryAction":{
      label: 'Save',
      eventName: 'primary-click',
      loading: _vm.groupImportModalSaveActionInProgress,
      disabled: _vm.groupImportModalSaveActionInProgress
    },"secondaryAction1":{
      label: 'Cancel',
      eventName: 'secondary1-click'
    },"bodyId":"group-import-modal--generic-dialog"},on:{"primary-click":_vm.saveClientUserImportModal,"secondary1-click":_vm.resetThenCloseGroupImportModal},scopedSlots:_vm._u([{key:"cs-form-dialog-content",fn:function(){return [_c('v-form',{ref:"group-import-modal--form",staticClass:"group-import-modal-form",attrs:{"lazy-validation":""}},[_c('v-spacer'),_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('input',{staticStyle:{"display":"none"},attrs:{"id":_vm.groupsImportFileInputId,"type":"file","accept":_vm.groupImportFileExtensionAttr,"aria-label":"groupsImportFileInputId"},on:{"change":function($event){return _vm.handleGroupImportFileInputChange($event)}}}),_c('v-btn',{staticClass:"normal-btn white--text continue-btn excursion-stepper-upload-btn v-btn-upload primary",attrs:{"id":"group-import-file-selection-button"},on:{"click":_vm.handleGroupImportFileSelectionClick}},[_c('i',{staticClass:"fa fa-upload",attrs:{"aria-hidden":"true"}}),_c('span',[_vm._v("Upload Group List CSV")])])],1)],1)],1),(_vm.groupImportFileInfo)?_c('v-flex',{staticClass:"form-label template-file-font",attrs:{"xs12":""}},[_vm._v(" File Name ")]):_vm._e(),(_vm.groupImportFileInfo)?_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{staticStyle:{"margin-left":"3px"},attrs:{"xs12":""}},[_vm._v(" "+_vm._s(_vm.groupImportFileInfoName)+" ")])],1)],1):_vm._e(),(_vm.groupImportFileInputErrorMessage)?_c('v-flex',{staticClass:"client-user-import-modal-error"},[_vm._v(" "+_vm._s(_vm.groupImportFileInputErrorMessage)+" ")]):_vm._e()],1)]},proxy:true}]),model:{value:(_vm.groupImportModalDisplayed),callback:function ($$v) {_vm.groupImportModalDisplayed=$$v},expression:"groupImportModalDisplayed"}}),_c('cs-form-dialog',{attrs:{"id":"dlg-generic","heading":"Groups Status","primaryAction":{
      label: 'OK',
      eventName: 'primary-click',
      disabled: _vm.groupImportModalSaveActionInProgress
    },"bodyId":"group-import-modal--generic-dialog"},on:{"primary-click":function($event){return _vm.handlegroupImportGenericDialogOkButtonClick(_vm.groupImportGenericDialogMessage)}},scopedSlots:_vm._u([{key:"cs-form-dialog-content",fn:function(){return [_c('v-spacer'),_c('v-card-text',[_c('div',{directives:[{name:"safe-html",rawName:"v-safe-html",value:(_vm.groupImportGenericDialogMessage),expression:"groupImportGenericDialogMessage"}]})])]},proxy:true}]),model:{value:(_vm.groupImportGenericDialogDisplayed),callback:function ($$v) {_vm.groupImportGenericDialogDisplayed=$$v},expression:"groupImportGenericDialogDisplayed"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }