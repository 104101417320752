<template>
  <v-container class="container">
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <div>
              <div class="text-h5 px-5 py-3">Site Settings</div>
              <div class="px-4 py-2">
                <v-flex xs12>
                  <span class="font-weight-bold">Default Administrators</span>
                </v-flex>
                <v-flex xs12 class="py-2">
                  <v-autocomplete
                    id="system-excursion-default--administrators"
                    ref="selectDefaultAdministrator"
                    v-model="defaultAdministrators"
                    label="Select one or more users"
                    :items="clientAdminList"
                    item-text="email"
                    item-value="email"
                    :multiple="true"
                    clearable
                    chips
                    :deletable-chips="true"
                    hide-details
                    :hide-selected="hideSelected"
                    solo
                    dense
                    :disabled="isLoadingUserList || isSaveInProgress"
                    :loading="isLoadingUserList"
                    loading-text="Loading..."
                    @change="
                      (event) =>
                        handleDefaultSelectionChange(event, $refs.selectDefaultAdministrator)
                    "
                  >
                    <template slot="item" slot-scope="data">
                      <span
                        :id="`system-excursion-default--administrators-item-${data.item.email}`"
                      >
                        {{ data.item.email }}
                      </span>
                    </template>
                  </v-autocomplete>
                </v-flex>
                <v-flex xs12>
                  <v-checkbox
                    id="system-excursion-default--enforce-default-administrators"
                    v-model="defaultAdministratorsEnforcementEnabled"
                    label="Enforce default administrators"
                    :disabled="isLoadingUserList || isSaveInProgress"
                    dense
                  ></v-checkbox>
                </v-flex>
              </div>
              <div class="px-4 py-2">
                <v-flex xs12 class="py-2">
                  <span class="font-weight-bold">Default Pre-Approvers</span>
                </v-flex>
                <v-flex xs12>
                  <v-autocomplete
                    id="system-excursion-default--approvers"
                    ref="selectDefaultApprovers"
                    v-model="defaultApprovers"
                    label="Select one or more users"
                    :items="clientApproverList"
                    item-text="email"
                    item-value="email"
                    :multiple="true"
                    clearable
                    chips
                    :deletable-chips="true"
                    hide-details
                    :hide-selected="hideSelected"
                    solo
                    dense
                    :disabled="isLoadingUserList || isSaveInProgress"
                    :loading="isLoadingUserList"
                    loading-text="Loading..."
                    @change="
                      (event) => handleDefaultSelectionChange(event, $refs.selectDefaultApprovers)
                    "
                  >
                    <template slot="item" slot-scope="data">
                      <span :id="`system-excursion-default--approvers-item-${data.item.email}`">
                        {{ data.item.email }}
                      </span>
                    </template>
                  </v-autocomplete>
                </v-flex>
                <v-flex xs12>
                  <v-checkbox
                    id="system-excursion-default--enforce-default-approvers"
                    v-model="defaultApproversEnforcementEnabled"
                    label="Enforce default pre-approvers"
                    :disabled="isLoadingUserList || isSaveInProgress"
                    dense
                  ></v-checkbox>
                </v-flex>
              </div>
              <div class="px-4 py-2">
                <v-flex xs12 class="py-2">
                  <span class="font-weight-bold">Default Final Approvers</span>
                </v-flex>
                <v-flex xs12>
                  <v-autocomplete
                    id="system-excursion-default--final-approvers"
                    ref="selectDefaultFinalApprovers"
                    v-model="defaultFinalApprovers"
                    label="Select one or more users"
                    :items="clientApproverList"
                    item-text="email"
                    item-value="email"
                    :multiple="true"
                    clearable
                    chips
                    :deletable-chips="true"
                    hide-details
                    :hide-selected="hideSelected"
                    solo
                    dense
                    :disabled="isLoadingUserList || isSaveInProgress"
                    :loading="isLoadingUserList"
                    loading-text="Loading..."
                    @change="
                      (event) =>
                        handleDefaultSelectionChange(event, $refs.selectDefaultFinalApprovers)
                    "
                  >
                    <template slot="item" slot-scope="data">
                      <span
                        :id="`system-excursion-default--final-approvers-item-${data.item.email}`"
                      >
                        {{ data.item.email }}
                      </span>
                    </template>
                  </v-autocomplete>
                </v-flex>
                <v-flex xs12>
                  <v-checkbox
                    id="system-excursion-default--enforce-default-final-approvers"
                    v-model="defaultFinalApproversEnforcementEnabled"
                    label="Enforce default final approvers"
                    :disabled="isLoadingUserList || isSaveInProgress"
                    dense
                  ></v-checkbox>
                </v-flex>
              </div>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="normal-btn white--text primary"
              :disabled="isLoadingUserList || isSaveInProgress"
              @click="save"
            >
              <v-icon v-if="isSaveInProgress" medium style="height: 8px">
                fa fa-spinner fa-pulse
              </v-icon>
              <span>Save</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <div>
      <cs-form-dialog
        v-model="genericDialogDisplayed"
        id="dlg-client-admin-site-setting--generic"
        bodyId="dlg-client-admin-site-setting--generic-body"
        heading="Site Settings"
        :primaryAction="{
          label: 'OK',
          eventName: 'primary-click'
        }"
        @primary-click="genericDialogDisplayed = false"
      >
        <template v-slot:cs-form-dialog-content>
          {{ genericDialogMessage }}
        </template>
      </cs-form-dialog>
    </div>
  </v-container>
</template>

<script>
import { uniqBy } from 'lodash';
import { mapState } from 'vuex';

export default {
  async mounted() {
    const { axiosInstance, eventPluginCommonAdapter, stbApiAdapter } = this.$app;
    const isVendorAuthScheme =
      !!this.$route.query['vendor-sid'] && !!this.$route.query['oauth-signature'];
    if (!isVendorAuthScheme) {
      await this.$store.dispatch('authorisation/validateAndSetUserTokenOrLogout', {
        query: this.$route.query,
        axiosInstance
      });
    }
    await this.$store.dispatch('common/initClientConfig', {
      queryParam: isVendorAuthScheme ? this.$route.query : {},
      eventPluginCommonAdapter,
      stbApiAdapter
    });

    if (!this.clientSiteSettingsButtonDiplayed) {
      this.$router.push('/excursions');
    }

    if (this.isAuthenticated) {
      await this.init();
    }
  },
  data() {
    return {
      genericDialogDisplayed: false,
      genericDialogMessage: '',
      isLoadingUserList: true,
      isSaveInProgress: false,
      defaultAdministrators: [],
      defaultApprovers: [],
      defaultFinalApprovers: [],
      defaultApproversEnforcementEnabled: false,
      defaultAdministratorsEnforcementEnabled: false,
      defaultFinalApproversEnforcementEnabled: false,
      clientAdminUsers: [],
      clientApproverUsers: [],
      hideSelected: false
    };
  },
  computed: {
    ...mapState({
      clientSiteSettingsButtonDiplayed: (state) => state.common.clientSiteSettingsButtonDiplayed,
      isAuthenticated: (state) => state.authorisation.isAuthenticated
    }),
    clientApproverList() {
      return uniqBy([...this.clientAdminUsers, ...this.clientApproverUsers], 'email');
    },
    clientAdminList() {
      return this.clientAdminUsers;
    }
  },
  methods: {
    async init() {
      this.isLoadingUserList = true;

      await this.initClientSiteData();
      await this.initUserList();

      this.isLoadingUserList = false;
      this.hideSelected = true;
    },
    async initClientSiteData() {
      const clientSiteSettingReponse = await this.$app.stbApiAdapter.getClientSiteSetting();

      if (!clientSiteSettingReponse.error && clientSiteSettingReponse.data) {
        const siteData = clientSiteSettingReponse.data || {};

        this.defaultAdministrators = siteData.defaultAdministrators || [];
        this.defaultApprovers = siteData.defaultApprovers || [];
        this.defaultFinalApprovers = siteData.defaultFinalApprovers || [];
        this.defaultAdministratorsEnforcementEnabled =
          siteData.defaultAdministratorsEnforcementEnabled || false;
        this.defaultApproversEnforcementEnabled =
          siteData.defaultApproversEnforcementEnabled || false;
        this.defaultFinalApproversEnforcementEnabled =
          siteData.defaultFinalApproversEnforcementEnabled || false;
      }
    },
    async initUserList() {
      const staffAdmins = [];
      const argHash = {
        filterParam: {
          role: 'client-admin',
          source: 'internal'
        }
      };

      const staffAdminResponse = await this.$app.stbApiAdapter.getClientStaffList(argHash);
      if (!staffAdminResponse?.error) {
        staffAdmins.push(...staffAdminResponse.data);
      }

      argHash.filterParam.role = 'client-admin-assistant';
      const staffAdminAssistantResponse = await this.$app.stbApiAdapter.getClientStaffList(argHash);
      if (!staffAdminAssistantResponse?.error) {
        staffAdmins.push(...staffAdminAssistantResponse.data);
      }

      this.clientAdminUsers.push(...uniqBy(staffAdmins, 'email'));

      argHash.filterParam.role = 'client-approver';
      const staffApproverResponse = await this.$app.stbApiAdapter.getClientStaffList(argHash);
      if (!staffApproverResponse?.error) {
        this.clientApproverUsers.push(...staffApproverResponse.data);
      }
    },
    async save() {
      this.isSaveInProgress = true;

      const newSiteData = {
        defaultAdministrators: [...this.defaultAdministrators],
        defaultApprovers: [...this.defaultApprovers],
        defaultFinalApprovers: [...this.defaultFinalApprovers],
        defaultAdministratorsEnforcementEnabled: this.defaultAdministratorsEnforcementEnabled,
        defaultApproversEnforcementEnabled: this.defaultApproversEnforcementEnabled,
        defaultFinalApproversEnforcementEnabled: this.defaultFinalApproversEnforcementEnabled
      };

      const patchResponse = await this.$app.stbApiAdapter.patchClientSiteSetting(newSiteData);

      if (!patchResponse?.error) {
        this.genericDialogMessage = 'Site settings has been saved.';
      } else {
        this.genericDialogMessage = 'Failed to save Site settings.';
      }

      this.isSaveInProgress = false;
      this.genericDialogDisplayed = true;
    },
    handleDefaultSelectionChange(event, ref) {
      ref.blur();
      this.$nextTick(() => {
        ref.focus();
      });
    }
  }
};
</script>
<style scoped>
.container {
  padding: 30px 50px;
}
</style>
