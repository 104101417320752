<template>
  <cs-view single-view>
    <div class="content" slot="content">
      <page-loading-animation :displayed="pageLoadingAnimationDisplayed()"></page-loading-animation>
      <v-dialog
        v-model="studentMedicalConditionsLoadingAnimationDisplayed"
        persistent
        max-width="600"
      >
        <v-card>
          <v-card-text>
            <div>
              Standby whilst we retrieve student medical data from your student information system.
              <br />This may take some time as we need to retrieve data for each individual student
              selected. <br />Please report any errors to our support team.
            </div>
            <div style="margin-top: 20px; text-align: center">
              <v-progress-circular
                :size="70"
                color="rgb(29, 175, 236)"
                class="loading-img"
                indeterminate
              ></v-progress-circular>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <div class="excursion-stepper excursion-builder" v-show="!pageLoadingAnimationDisplayed()">
        <v-layout
          v-show="clientLogoMarginDisplayed()"
          :class="vendorClientLogoEnabled ? 'mt-10' : 'mt-2'"
        >
        </v-layout>
        <v-layout xm xs v-show="vendorClientLogoEnabled && isAuthenticated">
          <v-layout class="client-logo">
            <img
              id="clientLogo"
              alt="clientLogo"
              :style="{ background: clientLogoBackgroundColor }"
            />
          </v-layout>
        </v-layout>
        <form class="mt-10" v-if="showError || !isAuthenticated">
          <v-layout class="mb-20">
            <v-flex>
              <v-img max-width="280px" :src="stbHorizontalLogo"></v-img>
            </v-flex>
          </v-layout>
          <v-layout class="mt-2 mb-3">
            <v-flex>
              You are not authorized to view Excursion Builder, please provide a correct auth token.
            </v-flex>
          </v-layout>
        </form>
        <form
          id="form"
          :class="vendorClientLogoEnabled ? 'mt-10' : ''"
          v-if="!showError && isAuthenticated"
        >
          <v-layout>
            <v-flex> </v-flex>
          </v-layout>
          <v-layout class="mb-20" v-show="stbLogoDisplayed">
            <v-img id="clientStepLogo" max-width="280px" :src="stbHorizontalLogo"></v-img>
          </v-layout>
          <v-layout class="mt-2 mb-3">
            <v-flex class="excursion-page-title-heading">
              <i class="fas fa-info-circle"></i>
              Fill in your excursion details and complete checklists to produce your excursion risk
              assessment
            </v-flex>
          </v-layout>
          <!-- step 1: Excursion Details-->
          <v-layout wrap v-if="vendorExcursionSidInputEnabled">
            <v-flex xs12 class="form-label">
              Excursion Manager ID
              <span class="red--text">&ast;</span>
            </v-flex>
            <v-flex xs12 class="excursion-detail-text">
              <v-text-field
                id="excursion-builder--excursion-manager-id"
                solo
                placeholder="Excursion Manager ID"
                class="name-textbox excursion-input"
                v-model="vendorExcursionSid"
                :error-messages="vendorExcursionSidErrors"
                @input="$v.vendorExcursionSid.$touch()"
                @blur="$v.vendorExcursionSid.$touch()"
                required
                :disabled="isExcursionInitiatedFromPcg"
              />
            </v-flex>
          </v-layout>
          <v-layout wrap>
            <v-flex xs12 class="form-label safe-trip-font">
              Excursion Name
              <span class="red--text">&ast;</span>
            </v-flex>
            <v-flex xs12 class="excursion-detail-text">
              <v-text-field
                id="excursion-builder--excursion-name"
                solo
                placeholder="Name"
                class="name-textbox excursion-input"
                v-model="name"
                :error-messages="nameErrors"
                required
                @input="$v.name.$touch()"
                @blur="$v.name.$touch()"
                :disabled="
                  !formControlEnabled || archivedExcursionDetected || isExcursionInitiatedFromPcg
                "
              />
            </v-flex>
          </v-layout>
          <v-layout wrap>
            <v-flex xs12 class="form-label safe-trip-font">
              Excursion Type
              <span class="red--text">&ast;</span>
              <v-tooltip right color="#ffffff" max-width="650px">
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                    id="excursion-builder--excursion-typ-tooltip--button"
                    v-on="{ ...tooltip }"
                    class="provider-tooltip-button"
                    icon
                  >
                    <i class="fas fa-info-circle"></i>
                  </v-btn>
                </template>
                <div class="type-tooltip-content">
                  <div
                    v-for="(excursionTypeInfoEntry, index) in excursionTypeInfoEntryList"
                    :key="index"
                  >
                    <!-- eslint-disable-next-line max-len -->
                    <b>{{ excursionTypeInfoEntry.label }}</b> -
                    {{ excursionTypeInfoEntry.description }}
                  </div>
                </div>
              </v-tooltip>
            </v-flex>
            <v-flex xs12 d-flex class="excursion-detail-text">
              <v-select
                id="excursion-builder--excursion-type"
                class="type-dropdown excursion-selection"
                v-model="excursionType"
                :items="excursionTypeChoiceList"
                item-text="name"
                item-value="id"
                solo
                :label="'Choose an Excursion Type'"
                :error-messages="selectErrors"
                required
                @blur="$v.excursionType.$touch()"
                @change="handleExcursionTypeSelectionChange()"
                :menu-props="{
                  closeOnClick: false,
                  closeOnContentClick: false,
                  openOnClick: false,
                  maxHeight: 400
                }"
                :disabled="
                  !formControlEnabled || archivedExcursionDetected || isExcursionInitiatedFromPcg
                "
                :loading="excursionTypeLoaded"
              >
                <template slot="item" slot-scope="data">
                  <div
                    :id="`excursion-builder--excursion-type--option-${String(data.item).replaceAll(
                      ' ',
                      ''
                    )}`"
                  >
                    {{ data.item }}
                  </div>
                </template>
              </v-select>
            </v-flex>
          </v-layout>
          <v-layout wrap v-if="showJurisdiction">
            <v-flex xs12 class="form-label safe-trip-font">
              Excursion Destination
              <span class="red--text">&ast;</span>
              <v-menu open-on-hover right offset-x max-width="650px">
                <template v-slot:activator="{ on: menu }">
                  <v-btn
                    id="excursion-builder--excursion-destination-tooltip--button"
                    v-on="{ ...menu }"
                    class="provider-tooltip-button"
                    icon
                  >
                    <i class="fas fa-info-circle"></i>
                  </v-btn>
                </template>
                <v-list class="jurisdiction-tooltip-content">
                  <v-list-item>
                    <p class="py-2 mb-0">
                      {{ jurisdictionTooltip }}
                      Click
                      <a
                        id="excursion-builder--excursion-juristiction-tooltip--link"
                        href="https://complispace-jira.atlassian.net/wiki/spaces/CS/pages/448056/STB+Excursion+Details"
                        target="_blank"
                      >
                        here
                      </a>
                      to learn more.
                    </p>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-flex>
            <v-flex xs12 d-flex>
              <v-select
                id="excursion-builder--excursion-jurisdiction"
                class="type-dropdown safe-trip-font excursion-selection safe-excursion-detail-text"
                v-model="jurisdiction"
                :items="jurisdictions"
                solo
                :label="'Choose an Excursion State'"
                :error-messages="selectErrors"
                required
                :menu-props="{
                  closeOnClick: false,
                  closeOnContentClick: false,
                  openOnClick: false,
                  maxHeight: 400
                }"
                :disabled="!formControlEnabled || archivedExcursionDetected"
                :loading="excursionJurisdictionLoaded"
              >
                <template slot="item" slot-scope="data">
                  <div
                    :id="`excursion-builder--excursion-destination--option-${String(
                      data.item
                    ).replaceAll(' ', '')}`"
                  >
                    {{ data.item }}
                  </div>
                </template>
              </v-select>
            </v-flex>
          </v-layout>
          <v-layout
            wrap
            class="excursion-builder--excursion-date-picker-container"
            v-if="excursionStartEndDateSetEnabled"
          >
            <v-flex xs12 class="form-label safe-trip-font"> Excursion Start Date </v-flex>
            <v-flex xs12>
              <v-menu
                ref="excursionStartDateMenu"
                v-model="excursionStartDateMenuDisplayed"
                :close-on-content-click="false"
                :return-value.sync="excursionStartDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    id="excursion-builder--excursion-start-date"
                    v-model="formattedExcursionStartDate"
                    label="Excursion Start Date"
                    prepend-icon="fa-calendar"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                    clearable
                    @click:clear="excursionStartDate = ``"
                    :disabled="
                      !formControlEnabled ||
                      archivedExcursionDetected ||
                      isExcursionInitiatedFromPcg
                    "
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="excursionStartDate"
                  no-title
                  scrollable
                  :allowed-dates="checkIfExcursionStartDateAllowed"
                  id="excursion-builder--excursion-start-date-picker"
                  :disabled="isExcursionInitiatedFromPcg"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    id="excursion-builder--excursion-start-date-menu--cancel--button"
                    text
                    color="primary"
                    @click="excursionStartDateMenuDisplayed = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    id="excursion-builder--excursion-start-date-menu--ok--button"
                    text
                    color="primary"
                    @click="$refs.excursionStartDateMenu.save(excursionStartDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
          <v-layout
            wrap
            class="excursion-builder--excursion-date-picker-container"
            v-if="excursionStartEndDateSetEnabled"
          >
            <v-flex xs12 class="form-label safe-trip-font"> Excursion End Date </v-flex>
            <v-flex xs12>
              <v-menu
                ref="excursionEndDateMenu"
                v-model="excursionEndDateMenuDisplayed"
                :close-on-content-click="false"
                :return-value.sync="excursionEndDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    id="excursion-builder--excursion-end-date"
                    v-model="formattedExcursionEndDate"
                    label="Excursion End Date"
                    prepend-icon="fa-calendar"
                    v-bind="attrs"
                    v-on="on"
                    readonly
                    clearable
                    @click:clear="excursionEndDate = ``"
                    :disabled="
                      !formControlEnabled ||
                      archivedExcursionDetected ||
                      disableDayExcursionEndDate ||
                      isExcursionInitiatedFromPcg
                    "
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="excursionEndDate"
                  no-title
                  scrollable
                  :allowed-dates="checkIfExcursionEndDateAllowed"
                  id="excursion-builder--excursion-end-date-picker"
                  :disabled="isExcursionInitiatedFromPcg"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    id="excursion-builder--excursion-end-date-menu--cancel--button"
                    text
                    color="primary"
                    @click="excursionEndDateMenuDisplayed = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    id="excursion-builder--excursion-end-date-menu--ok--button"
                    text
                    color="primary"
                    @click="$refs.excursionEndDateMenu.save(excursionEndDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
          <!-- step 2: Student Medical and Ratios-->
          <hr class="mt-4 mb-4" v-if="excursionType && excursionHighRiskStudentMedicalEnabled" />
          <v-layout v-if="excursionType && excursionHighRiskStudentMedicalEnabled">
            <v-flex>
              <student-selection
                :formControlEnabled="formControlEnabled && !archivedExcursionDetected"
                @toggle="toggleStudentSelection"
                @save="saveChangesThenRevertToDraft"
              ></student-selection>
              <risk-category
                :riskCategories="highRiskStudentMedicalConditionsRisks"
                :formControlEnabled="formControlEnabled && !archivedExcursionDetected"
                :saveDraft="saveChangesThenRevertToDraft"
              />
              <staff-student-supervision
                :formControlEnabled="formControlEnabled && !archivedExcursionDetected"
                @toggleSupervision="toggleStaffStudentSupervision"
              ></staff-student-supervision>
              <div
                v-if="staffToStudentGenderRatioInfoEnabled && staffStudentSupervisionDisplayed"
                class="sub-category-section"
              >
                <v-flex xs12 class="sub-label gender-ratios-label">
                  <v-layout align-center justify-space-between>
                    <div>Supervisor & Student Gender Ratios (Optional)</div>
                  </v-layout>
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex xs12>
                  <v-textarea
                    id="student-gender-ratios"
                    ref="genderRatiosInput"
                    no-resize="true"
                    solo
                    placeholder="Enter any additional numbers, ratios or clarification on gender ratios where required by school policies"
                    rows="100"
                    v-model="staffToStudentGenderRatioInfo"
                    :disabled="!formControlEnabled || archivedExcursionDetected"
                  ></v-textarea>
                  <div
                    v-if="mutipleLineDetected"
                    id="excursion-builder--display-person-responsible-show-more--button"
                    @click="
                      displayDetailsDialog(
                        'Supervisor & Student Gender Ratios',
                        staffToStudentGenderRatioInfo
                      )
                    "
                    class="person-responsible-show-more"
                  >
                    Show more
                  </div>
                </v-flex>
              </div>
            </v-flex>
          </v-layout>

          <!-- step 3: Risk Categories-->
          <hr
            class="mt-4 mb-4"
            v-if="excursionType != '' && excursionType != null && excursionType != undefined"
          />
          <v-layout v-if="excursionType">
            <v-flex>
              <v-flex class="update-provider-stepper" v-if="providerUpdateActionDisplayed">
                <v-flex class="update-provider-stepper-text">
                  <i class="fas fa-info-circle"></i>
                  Click the Update Providers button for any immediate updates that are required.
                </v-flex>
                <v-flex class="update-provider-stepper-button">
                  <update-providers
                    :buttonUpdateProvidersEnabled="
                      buttonUpdateProvidersEnabled && !archivedExcursionDetected
                    "
                    :formControlEnabled="formControlEnabled"
                  >
                  </update-providers>
                </v-flex>
              </v-flex>
              <v-flex class="riskManifest">
                <i class="fas fa-info-circle"></i>
                <span>The SafeTripBuilder risk manifest is available </span>
                <span class="riskManifestLink">
                  <span
                    id="excursion-builder--excursion-risk-manifest--link"
                    @click="registerRiskManifestLinkClickEvent()"
                    >here</span
                  >
                </span>
                <span> if you want to view all supported risk selections. </span>
              </v-flex>
              <risk-category
                :riskCategories="nonHighRiskStudentMedicalConditionsRisks"
                :formControlEnabled="formControlEnabled && !archivedExcursionDetected"
                :saveDraft="saveChangesThenRevertToDraft"
              />
              <export-excursion-policy
                style="margin-left: -10px"
                :excursionPolicyExportActionEnabled="hasExcursion()"
                :submitExcursion="submit"
                :valuesChanged="valuesChanged"
                :excursionBuilderViewDisplayed="true"
              ></export-excursion-policy>
            </v-flex>
          </v-layout>

          <!-- step 4: Additional Information-->
          <hr class="mt-4 mb-4" />
          <v-layout v-if="excursionType && excursionAdditionalInformationEnabled">
            <v-flex>
              <v-layout wrap v-if="clientTemplate.sid">
                <v-flex xs12 class="form-label safe-trip-font"> Pro Forma Template </v-flex>
                <v-flex xs12>
                  {{ clientTemplate.filename }}
                  <v-icon
                    :id="getDownloadFileBtnId(clientTemplate.filename)"
                    @click="downloadFile(clientTemplate)"
                    medium
                    style="font-size: 15px; height: 17px; margin-left: 0.7%"
                    >fa fa-download</v-icon
                  >
                </v-flex>
              </v-layout>
              <additional-info-list
                :additionalInformationItems="additionalInformationItems"
                :formControlEnabled="formControlEnabled && !archivedExcursionDetected"
                @setAdditionalInformationValid="setAdditionalInformationValid"
                ref="additionalInfoList"
              ></additional-info-list>
            </v-flex>
          </v-layout>

          <!-- step 5: Excursion Risk Assessment-->
          <hr class="mt-4 mb-4" />
          <v-layout>
            <v-flex class="main-title">
              {{ excursionStepperHeadingLabel.EXCURSION_RISK_ASSESSMENT }}
            </v-flex>
          </v-layout>
          <v-layout class="mt-3" id="riskOverallContainer">
            <risk-rating-overall riskRatingRefname="residual"></risk-rating-overall>
          </v-layout>
          <v-layout
            class="mt-3"
            id="inherentRiskContainer"
            v-if="excursionInherentRiskRatingDetected"
          >
            <risk-rating-summary
              headingLabel="Inherent Rating"
              :tooltipBubbleText="inherentRiskRatingTooltipBubbleText"
              riskRatingRefname="inherent"
            ></risk-rating-summary>
          </v-layout>
          <v-layout class="mt-3" id="residualRiskContainer">
            <risk-rating-summary
              :headingLabel="residualRiskRatingSummaryHeadingLabel"
              :tooltipBubbleText="residualRiskRatingTooltipBubbleText"
              riskRatingRefname="residual"
            ></risk-rating-summary>
          </v-layout>
          <v-flex xs12 class="form-label safe-trip-font" v-if="riskAssessmentNotesDisplay">
            Notes
          </v-flex>
          <v-flex xs12 v-if="riskAssessmentNotesDisplay">
            <v-textarea
              id="risk-assessment-notes"
              solo
              :no-resize="true"
              placeholder="Notes (optional)"
              row-height="100"
              rows="100"
              v-model="riskAssessmentNotes"
              :disabled="!formControlEnabled || archivedExcursionDetected"
            ></v-textarea>
          </v-flex>
          <v-layout align-start justify-start class="create-risk-assessment">
            <div>
              <export-excursion-risk-assessment
                :excursionPolicyExportActionEnabled="
                  isInvalid || !riskCategoriesValid || hasExcursion()
                "
                :registerRiskAssessmentExportEvent="registerRiskAssessmentExportEvent"
                :submitExcursion="submit"
                :valuesChanged="valuesChanged"
              ></export-excursion-risk-assessment>
            </div>
          </v-layout>

          <!-- step 6: Mandatory Excursion Checklists-->
          <hr class="mt-4 mb-4" v-if="excursionType" />
          <div @click.stop="toggleMandatoryChecklist()" v-if="excursionType" id="div">
            <v-layout align-center justify-space-between>
              <div>
                <v-layout align-center justify-space-between class="category-section">
                  <v-icon class="icon">
                    {{
                      mandatoryChecklistDisplayed ? 'keyboard_arrow_down' : 'keyboard_arrow_left'
                    }}
                  </v-icon>
                  <div class="form-label mandatory-checklist">Mandatory Excursion Checklists</div>
                </v-layout>
              </div>
            </v-layout>
          </div>
          <div
            class="checkBoxContainer"
            v-if="
              mandatoryChecklistBatchConfirmationAgreementEnabled && checklistAttestationEnabled
            "
          >
            <v-checkbox
              color="#c80063"
              v-model="mandatoryChecklistBatchConfirmationAgreement"
              class="checkAll"
              :checked="mandatoryChecklistBatchConfirmationAgreement"
              id="`check-all-list`"
              testing-id="mandatoryChecklistBatchConfirmationAgreementCheckbox"
              :disabled="archivedExcursionDetected"
            ></v-checkbox>
            <span class="checkAllLabel" for="`check-all-list`">
              I agree I have completed the following checklists
            </span>
          </div>
          <div :hidden="!mandatoryChecklistDisplayed" v-if="excursionType">
            <mandatory-checklist
              :saveDraft="saveChangesThenRevertToDraft"
              :formControlEnabled="formControlEnabled"
            />
          </div>

          <!-- step 7: Excursion Risk Management Confirmation-->
          <hr class="mt-4 mb-4" />
          <v-layout>
            <v-flex class="main-title">
              {{ excursionStepperHeadingLabel.EXCURSION_RISK_MANAGEMENT_CONFIRMATION }}
            </v-flex>
          </v-layout>
          <v-flex class="confirmation-info-text">
            <i class="fas fa-info-circle"></i>
            Confirm acknowledgement by reading, agreeing and ticking below.
          </v-flex>
          <v-layout align-start justify-start class="create-risk-assessment">
            <excursion-confirmation
              :disabledCheckbox="isInvalid || !formControlEnabled || archivedExcursionDetected"
              @setAgreementConfirmationManuallyTicked="setAgreementConfirmationManuallyTicked"
            ></excursion-confirmation>
          </v-layout>

          <v-layout align-end justify-end class="margin-b">
            <v-btn
              class="normal-btn white--text btnSave primary"
              @click.native="displayExcursionApprovalToggleDialog()"
              :name="'btnApprove'"
              :id="'btnApprove'"
              v-if="excursionApprovalButtonDisplayed"
              :disabled="
                isInvalid ||
                !additionalInformationValid ||
                archivedExcursionDetected ||
                excursionApprovalToggleActionInProgress ||
                (approvedExcursionDetected ? formControlEnabled : !formControlEnabled)
              "
            >
              <v-icon v-if="excursionApprovalToggleActionInProgress">fa fa-spinner fa-pulse</v-icon>
              Approve
            </v-btn>
            <v-btn
              class="normal-btn white--text btnSave primary"
              @click.native="displayExcursionApprovalToggleDialog()"
              :name="'btnUnapprove'"
              :id="'btnUnapprove'"
              v-if="excursionUnapprovalButtonDisplayed"
              :disabled="
                isInvalid ||
                !additionalInformationValid ||
                excursionApprovalToggleActionInProgress ||
                (approvedExcursionDetected ? formControlEnabled : !formControlEnabled) ||
                archivedExcursionDetected
              "
            >
              <v-icon v-if="excursionApprovalToggleActionInProgress">fa fa-spinner fa-pulse</v-icon>
              Undo Approval
            </v-btn>
            <v-btn
              :disabled="
                isInvalid ||
                !additionalInformationValid ||
                excursionSaveActionInProgress ||
                !formControlEnabled ||
                archivedExcursionDetected
              "
              class="normal-btn white--text btnSave primary"
              @click.native="saveChangesAsDraft()"
              name="btnSave"
              id="btnSave"
            >
              <v-icon v-if="excursionSaveActionInProgress">fa fa-spinner fa-pulse</v-icon>
              Save Excursion
            </v-btn>
            <v-btn
              class="normal-btn"
              @click.native="resetConfirmationDialogDisplayed = true"
              id="btnClearAndRestart"
              :disabled="!formControlEnabled || archivedExcursionDetected"
            >
              Clear and Restart
            </v-btn>
          </v-layout>
          <hr class="mt-4 mb-4" />
          <v-layout>
            <v-flex class="main-title">
              {{ excursionStepperHeadingLabel.PREDEPARTURE_AND_POST_EXCURSION }}
            </v-flex>
          </v-layout>
          <v-layout align-start justify-start class="create-risk-assessment">
            <div>
              <view-predeparture-checklist
                :savePredeparture="submit"
                :formControlEnabled="formControlEnabled"
              ></view-predeparture-checklist>
            </div>
            <div>
              <v-btn
                class="normal-btn white--text primary"
                :disabled="true"
                id="btnIncidentReporting"
                testing-id="btnIncidentReporting"
              >
                <font>Incident Reporting</font>
              </v-btn>
            </div>
            <div>
              <v-btn
                v-if="approvedExcursionDetected && excursionArchivalButtonDiplayed"
                class="normal-btn white--text primary"
                id="btnArchived"
                testing-id="btnArchived"
                :disabled="archivedExcursionDetected"
                @click.native="excursionArchiveToggleDialogDisplayed = true"
              >
                <font>Archive Excursion</font>
              </v-btn>
            </div>
          </v-layout>
          <!-- Excursion History -->
          <excursion-history v-if="excursionHistoryDisplayed" />
          <div>
            <v-dialog
              v-model="successDialogDisplayed"
              v-if="!presaveConfirmed"
              persistent
              max-width="450"
            >
              <v-card>
                <v-card-title class="headline center">
                  Excursion has been saved successfully
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click.native="confirmSuccess()"
                    class="normal-btn white--text primary"
                    id="confirmSuccess"
                    >OK</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
          <div>
            <v-dialog
              v-model="failureDialogDisplayed"
              v-if="!presaveConfirmed"
              persistent
              max-width="490"
            >
              <v-card>
                <v-card-title class="headline center">
                  <div v-safe-html="errorMessage"></div>
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click.native="failureDialogDisplayed = false"
                    class="normal-btn white--text primary"
                    id="failureDialogDisplayed"
                    >OK</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
          <div>
            <v-dialog v-model="resetConfirmationDialogDisplayed" persistent max-width="290">
              <v-card>
                <v-card-text>
                  Are you sure you want to clear and restart your excursion?
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="normal-btn white--text btnClear primary"
                    @click.native="clear()"
                    id="btnConfirmClear"
                  >
                    Clear and Restart
                  </v-btn>
                  <v-btn
                    id="excursion-builder--reset-excursion-confirmation-dialog--cancel--button"
                    class="normal-btn"
                    @click.native="resetConfirmationDialogDisplayed = false"
                  >
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
          <div>
            <v-dialog v-model="excursionTypeWarningDisplayed" persistent max-width="490">
              <v-card>
                <v-card-text>
                  You are about to change your Excursion Type after previously saving your
                  selection. Doing so will clear your excursion and you will have to re-enter your
                  details. It will keep other information except risk categories.<br /><br />
                  Are you sure you want to continue?
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="normal-btn white--text primary"
                    @click.native="
                      excursionTypeWarningDisplayed = false;
                      clearCategoriesOnExcursionTypeUpdate();
                    "
                    id="btnUpdateExcursionTypeOK"
                    >OK</v-btn
                  >
                  <v-btn
                    class="normal-btn"
                    @click.native="
                      excursionTypeWarningDisplayed = false;
                      cancelExcursionTypeUpdate();
                    "
                    id="btnUpdateExcursionTypeCancel"
                    >Cancel</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
          <div>
            <v-dialog v-model="changesSaveDialogDisplayed" persistent max-width="490">
              <v-card>
                <v-card-text>
                  Changes have been made to this excursion, are you sure you want to save?
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    id="excursion-builder--changes-save-dialog--save--button"
                    class="normal-btn white--text primary"
                    @click.native="saveChangesThenRevertToDraft()"
                  >
                    Save
                  </v-btn>
                  <v-btn
                    id="excursion-builder--changes-save-dialog--cancel--button"
                    class="normal-btn"
                    @click.native="changesSaveDialogDisplayed = false"
                  >
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
          <div>
            <v-dialog v-model="excursionApprovalToggleDialogDisplayed" persistent max-width="490">
              <v-card>
                <v-card-text>
                  Are you sure you want to
                  {{ approvedExcursionDetected ? 'undo approval' : 'approve' }}
                  this excursion?
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    :id="
                      approvedExcursionDetected
                        ? 'excursion-builder--unapprove-trigger--button'
                        : 'excursion-builder--approve-trigger--button'
                    "
                    :testing-id="approvedExcursionDetected ? 'unapproveTrigger' : 'approveTrigger'"
                    class="normal-btn white--text primary"
                    @click.native="
                      approvedExcursionDetected ? unapproveExcursion() : approveExcursion()
                    "
                  >
                    {{ approvedExcursionDetected ? 'Undo Approval' : 'Approve' }}
                  </v-btn>
                  <v-btn
                    id="excursion-builder--excursion-approval-toggle-dialog--cancel--button"
                    class="normal-btn"
                    @click.native="excursionApprovalToggleDialogDisplayed = false"
                  >
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
          <div>
            <v-dialog v-model="excursionArchiveToggleDialogDisplayed" persistent max-width="490">
              <v-card>
                <v-card-text> Archive this excursion? Note this is not reversible. </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    id="excursion-builder--archive-trigger--button"
                    testing-id="archiveTrigger"
                    class="normal-btn white--text primary"
                    @click.native="archiveExcursion()"
                  >
                    Archive
                  </v-btn>
                  <v-btn
                    id="excursion-builder--archive-trigger--cancel--button"
                    class="normal-btn"
                    @click.native="excursionArchiveToggleDialogDisplayed = false"
                  >
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
          <div>
            <v-dialog v-model="genericDialogDisplayed" persistent max-width="410">
              <v-card>
                <v-card-title>{{ genericDialogTitle }}</v-card-title>
                <v-card-text>
                  <div v-safe-html="genericDialogMessage"></div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click.native="genericDialogDisplayed = false"
                    class="normal-btn white--text primary"
                    id="risk-category--generic-dialog--ok--button"
                    >OK</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </form>
      </div>
    </div>
  </cs-view>
</template>

<script>
import * as types from '@/store/mutationTypes';
import { CSBase } from '@complispace/cs-design-system';
import { validationMixin } from 'vuelidate';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { mapState } from 'vuex';
import excursionJurisdictions from '../lib/const/excursionJurisdictions';
import excursionStatus from '../lib/const/excursionStatus';
import excursionStepperHeading from '../lib/const/excursionStepperHeading';
import excursionTypeInfo from '../lib/const/excursionTypeInfo';
import trackableEvent from '../lib/const/trackableEvent';
import stbHorizontalLogo from '../../public/StbHorizontalLogo.svg';
import defaultRiskMatrix from '../lib/const/riskMatrixTemplate';
import { populateErrorMessage } from '../lib/errorMessages';
import riskCategoryLabel from '../lib/const/riskCategoryLabel';
import RiskCategory from './RiskCategory';
import ExportExcursionPolicy from './ExportExcursionPolicy';

export default {
  extends: CSBase,

  components: {
    RiskCategory,
    ExportExcursionPolicy
  },

  mixins: [validationMixin],

  validations: {
    name: {
      required
    },
    excursionType: {
      required
    },
    vendorExcursionSid: {
      required
    },
    excursionStartDate: {
      required: requiredIf('excursionStartEndDateSetRequired')
    },
    excursionEndDate: {
      required: requiredIf('excursionStartEndDateSetRequired')
    }
  },

  props: {
    propHasParentComponent: Boolean
  },

  data() {
    return {
      stbHorizontalLogo,
      excursionApprovalToggleDialogDisplayed: false,
      excursionArchiveToggleDialogDisplayed: false,
      successDialogDisplayed: false,
      failureDialogDisplayed: false,
      resetConfirmationDialogDisplayed: false,
      excursionId: 0,
      pdf: {
        title: 'master excursion risk assessment - sample',
        path: 'doc'
      },
      mandatoryChecklistDisplayed: true,
      excursionTypeWarningDisplayed: false,
      originalExcursionType: '',
      additionalInformationValid: true,
      newExcursionType: '',
      excursionSaveActionInProgress: false,
      excursionApprovalToggleActionInProgress: false,
      excursionArchiveToggleActionInProgress: false,
      errorMessage: '',
      presaveConfirmed: false,
      valuesChanged: false,
      firstTimeLoaded: false,
      changesSaveDialogDisplayed: false,
      agreementConfirmationManuallyTicked: false,
      additionalInformationChanged: false,
      excursionStatusChanged: false,
      excursionSetByExcursionChanges: false,
      excursionSetByRiskChanges: false,
      pageRefreshRequired: false,
      jurisdictionTooltip:
        "The content of the destination state should be considered when travelling interstate. Given that we research content across each State and Territory's Department of Education, in some cases there may be additional information to consider even if you are only travelling interstate for a day excursion.",
      genericDialogDisplayed: false,
      genericDialogMessage: '',
      genericDialogTitle: '',
      staffStudentSupervisionDisplayed: true,
      studentSelectionDisplayed: true,
      excursionStartDateMenuDisplayed: false,
      excursionEndDateMenuDisplayed: false,
      excursionCommonAdapter: false,
      checklistAttestationEnabled: false
    };
  },

  computed: {
    ...mapState({
      excursionBuilderButtonDisplayed: (state) => state.common.excursionBuilderButtonDisplayed,
      isInitClientConfigInProgress: (state) => state.authorisation.isInitClientConfigInProgress,
      isAuthenticated: (state) => state.authorisation.isAuthenticated
    }),
    mandatoryChecklistBatchConfirmationAgreement: {
      get() {
        return this.$store.state.common.excursionDc.mandatoryChecklistBatchConfirmationAgreement;
      },
      set(value) {
        this.$store.commit(
          types.COMMON_SET_MANDATORY_CHECKLIST_BATCH_CONFIRMATION_AGREEMENT,
          value
        );
      }
    },
    name: {
      get() {
        return this.$store.state.common.excursionDc.name;
      },
      set(value) {
        this.$store.commit(types.COMMON_SET_EXCURSION_NAME, value);
      }
    },
    providerUpdateActionDisplayed() {
      return this.$store.state.common.providerUpdateActionDisplayed;
    },
    excursionType: {
      get() {
        if (this.$store.state.common.excursionDc.typeId) {
          this.setOriginalExcursionType(this.$store.state.common.excursionDc.typeId);
        }

        return this.$store.state.common.excursionDc.typeId;
      },
      async set(value) {
        if (!this.$store.state.common.excursionDc.typeId) {
          this.$store.commit(types.COMMON_SET_EXCURSION_TYPE, value);
          this.originalExcursionType = value;
          if (value) {
            this.$store.commit(types.COMMON_SET_EXCURSION_DC_CATEGORIES, []);

            await this.$store.dispatch('common/getAllRisksByExcursionTypeName', {
              stbApiAdapter: this.$app.stbApiAdapter,
              store: this.$store,
              queryParam: this.$route.query,
              excursionTypeName: value
            });
          }
        } else {
          this.newExcursionType = value;
          this.excursionTypeWarningDisplayed = true;
        }
      }
    },
    jurisdiction: {
      get() {
        return this.$store.state.common.excursionDc.jurisdiction === '' ||
          !this.$store.state.common.excursionDc.jurisdiction
          ? this.$store.state.common.apiDataDefaultJurisdiction
          : this.$store.state.common.excursionDc.jurisdiction;
      },
      set(value) {
        this.$store.commit(types.COMMON_SET_EXCURSION_JURISDICTION, value);
      }
    },
    vendorExcursionSid: {
      get() {
        if (this.vendorExcursionSidInputEnabled) {
          return this.$store.state.common.excursionDc.vendorData.vendorExcursionSid;
        }
        return this.$store.state.common.excursionDc.vendorData.vendorExcursionSid || 0;
      },
      set(value) {
        this.$store.commit(types.COMMON_SET_VENDOR_EXCURSION_ID, value);
      }
    },
    excursionStartDate: {
      get() {
        return this.$store.state.common.excursionDc.startDate;
      },
      set(value) {
        this.$store.commit(types.COMMON_SET_EXCURSION_START_DATE, value);
      }
    },
    formattedExcursionStartDate: {
      get() {
        return this.formatExcursionDate(this.excursionStartDate);
      },
      set(value) {
        return value;
      }
    },
    excursionEndDate: {
      get() {
        return this.$store.state.common.excursionDc.endDate;
      },
      set(value) {
        this.$store.commit(types.COMMON_SET_EXCURSION_END_DATE, value);
      }
    },
    formattedExcursionEndDate: {
      get() {
        return this.formatExcursionDate(this.excursionEndDate);
      },
      set(value) {
        return value;
      }
    },
    excursionTypeChoiceList() {
      if (this.archivedExcursionDetected) {
        return [`${this.$store.state.common.excursionDc.typeId}`];
      }
      return Object.keys(this.$store.state.common.excursionTypeChoiceList);
    },
    approvedExcursionDetected() {
      return excursionStatus.APPROVED === this.resolvedExcursionStatus;
    },
    archivedExcursionDetected() {
      return excursionStatus.ARCHIVED === this.resolvedExcursionStatus;
    },
    excursionArchivalButtonDiplayed() {
      return this.$store.state.common.excursionArchivalButtonDiplayed;
    },
    jurisdictions() {
      return excursionJurisdictions;
    },
    showJurisdiction() {
      return this.$app.excursionCommon.showJurisdiction(this.$store.state);
    },
    highRiskStudentMedicalConditionsRisks() {
      return this.$store.getters['common/getHighRiskStudentMedicalConditionsRisks'];
    },
    nonHighRiskStudentMedicalConditionsRisks() {
      return this.$store.getters['common/getNonHighRiskStudentMedicalConditionsRisks'];
    },
    isExcursionInitiatedFromPcg() {
      return (
        this.$store.state.common.userAuthSchemeApiParam?.['vendor-sid'] === '10000001' &&
        !!this.$store.state.common.excursionDc.vendorData.vendorExcursionSid &&
        !this.newExcursionDetected
      );
    },
    risks() {
      const { allRisks } = this.$store.state.common;
      return allRisks.filter(
        (r) => r.name !== riskCategoryLabel.HIGH_RISK_STUDENT_MEDICAL_CONDITIONS
      );
    },
    selectErrors() {
      const errors = [];
      if (!this.$v.excursionType.$dirty) return errors;
      if (!this.$v.excursionType.required) {
        errors.push('Excursion Type is required');
      }
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      if (!this.$v.name.required) {
        errors.push('Excursion is required.');
      }

      return errors;
    },
    vendorExcursionSidErrors() {
      const errors = [];

      if (this.vendorExcursionSidInputEnabled) {
        if (!this.$v.vendorExcursionSid.$dirty) return errors;
        if (!this.$v.vendorExcursionSid.required) {
          errors.push('Excursion Manager ID is required.');
        }
      }

      return errors;
    },
    showError() {
      return this.$store.state.common.showError ? this.$store.state.common.showError : false;
    },
    allSelectedActivityRisk() {
      return this.$store.getters['common/getSelectedActivityClasses'][0];
    },
    agreementConfirmationTicked() {
      if (!this.$store.state.common.excursionDc.agreementConfirmation) {
        return false;
      }
      return this.$store.state.common.excursionDc.agreementConfirmation.ticked;
    },
    additionalInformationItems: {
      get() {
        const { additionalInformationItems } = this.$store.state.common.excursionDc;
        const infors = this.$app.lodash.cloneDeep(additionalInformationItems).sort((a, b) => {
          return a.orderId - b.orderId;
        });
        if (infors && infors.length > 0) {
          const inforsAdded = infors.filter((r) => !r.isDeleted);
          if (inforsAdded.length === 1) {
            infors.forEach((r) => {
              const localR = r;
              if (!localR.isDeleted) {
                if (localR.filename || localR.fileSummary || localR.comment) {
                  localR.showDeleteButton = true;
                }
              }
            });
          } else {
            infors.forEach((r) => {
              const localR = r;
              localR.showDeleteButton = true;
            });
          }

          return infors;
        }

        return [
          {
            fileSummary: '',
            comment: '',
            showDeleteButton: false
          }
        ];
      }
    },
    vendorExcursionSidInputEnabled() {
      return this.$store.state.common.vendorExcursionSidInputEnabled;
    },
    excursionAdditionalInformationEnabled() {
      return this.$store.state.common.excursionAdditionalInformationEnabled;
    },
    excursionHighRiskStudentMedicalEnabled() {
      return this.$store.state.common.excursionHighRiskStudentMedicalEnabled;
    },
    isInvalid() {
      return this.$v.$invalid;
    },
    riskCategoriesValid() {
      return this.$store.getters['common/getSelectedCategoryCount'] > 0;
    },
    excursionDisplayReady() {
      return this.$store.state.common.excursionDisplayReady;
    },
    excursionStudentMedicalConditionsDisplayReady() {
      return this.$store.state.common.excursionStudentMedicalConditionsDisplayReady;
    },
    excursionLogoDisplayReady() {
      return this.$store.state.common.excursionLogoDisplayReady;
    },
    clientLogoBackgroundColor() {
      return this.$store.state.common.clientLogoBackgroundColor;
    },
    vendorClientLogoEnabled() {
      return this.$store.state.common.vendorClientLogoEnabled;
    },
    stbLogoDisplayed() {
      const { query } = this.$route;

      let stbLogoDisplayed = true;
      if (query && query.referrer && query.referrer.toLowerCase() === 'pcg') {
        stbLogoDisplayed = false;
      }

      return stbLogoDisplayed;
    },
    formControlEnabled() {
      return (
        !(this.resolvedExcursionStatus === excursionStatus.APPROVED) &&
        !(this.resolvedExcursionStatus === excursionStatus.DRAFT)
      );
    },
    buttonUpdateProvidersEnabled() {
      return !(this.resolvedExcursionStatus === excursionStatus.DRAFT);
    },
    excursionTypeLoaded() {
      return this.$store.state.common.excursionTypeLoaded;
    },
    excursionJurisdictionLoaded() {
      return this.$store.state.common.excursionJurisdictionLoaded;
    },
    excursions() {
      return JSON.parse(JSON.stringify(this.$store.state.common.excursionDc));
    },
    clientTemplate() {
      let { clientTemplate } = this.$store.state.common.excursionDc;
      if (this.excursionId === 0) {
        clientTemplate = this.$store.state.common.clientTemplate;
      }
      return clientTemplate;
    },
    resolvedExcursionStatus() {
      return this.$store.state.common.excursionDc.status;
    },
    excursionHistoryDisplayed() {
      return this.$store.state.common.excursionDc.id > 0;
    },
    pcgIntegrationDetected() {
      const { query } = this.$route;
      return query && query.referrer && query.referrer.toLowerCase() === 'pcg';
    },
    excursionApprovalButtonDisplayed() {
      const { vendorExcursionSid } = this.$store.state.common.excursionDc.vendorData;
      return (
        this.$store.state.common.excursionDc.agreementConfirmation.ticked &&
        !this.pcgIntegrationDetected &&
        !(vendorExcursionSid && parseInt(vendorExcursionSid, 10) > 0) &&
        excursionStatus.APPROVED !== this.resolvedExcursionStatus &&
        this.$store.state.common.excursionApprovalButtonDisplayed
      );
    },
    excursionUnapprovalButtonDisplayed() {
      return (
        excursionStatus.APPROVED === this.resolvedExcursionStatus &&
        !this.pcgIntegrationDetected &&
        this.$store.state.common.excursionUnapprovalButtonDisplayed
      );
    },
    excursionStepperHeadingLabel() {
      return excursionStepperHeading;
    },
    excursionTypeInfoEntryList() {
      return Object.values(excursionTypeInfo);
    },
    excursionStudentFetchTriggered() {
      return this.$store.state.common.excursionStudentFetchTriggered;
    },
    studentMedicalConditionsLoadingAnimationDisplayed() {
      return (
        !this.pageLoadingAnimationDisplayed() &&
        this.excursionStudentFetchTriggered &&
        !this.excursionStudentMedicalConditionsDisplayReady
      );
    },
    excursionRiskOverall() {
      return this.$store.state.common.excursionDc.riskOverall;
    },
    excursionInherentRiskRatingEnabled() {
      return this.$store.state.common.excursionInherentRiskRatingEnabled;
    },
    excursionInherentRiskRatingDetected() {
      const riskOverall = this.excursionRiskOverall;
      const localExcursionInherentRiskRatingDetected =
        this.excursionInherentRiskRatingEnabled &&
        riskOverall &&
        riskOverall.residualRiskRating &&
        riskOverall.inherentRiskRating;

      return localExcursionInherentRiskRatingDetected;
    },
    residualRiskRatingSummaryHeadingLabel() {
      let localResidualRiskRatingSummaryHeadingLabel =
        'CompliSpace Safe Excursions Recommended Rating';

      if (this.excursionInherentRiskRatingDetected) {
        localResidualRiskRatingSummaryHeadingLabel = 'Residual Rating';
      }

      return localResidualRiskRatingSummaryHeadingLabel;
    },
    residualRiskRatingTooltipBubbleText() {
      return this.excursionInherentRiskRatingDetected
        ? 'Residual risk is the level of risk that remains after controls have been applied.'
        : '';
    },
    inherentRiskRatingTooltipBubbleText() {
      return this.excursionInherentRiskRatingDetected
        ? 'Inherent risk is the level of risk that exists where no controls have been applied.'
        : '';
    },
    excursionUserRiskRatingEnabled() {
      return this.$store.state.common.excursionDc.excursionUserRiskRatingEnabled;
    },
    staffToStudentGenderRatioInfoEnabled() {
      return this.$store.state.common.staffToStudentGenderRatioInfoEnabled;
    },
    mandatoryChecklistBatchConfirmationAgreementEnabled() {
      return this.$store.state.common.mandatoryChecklistBatchConfirmationAgreementEnabled;
    },
    staffToStudentGenderRatioInfo: {
      get() {
        return this.$store.state.common.excursionDc.staffToStudentGenderRatioInfo;
      },
      set(value) {
        this.$store.commit(types.COMMON_SET_STAFF_TO_STUDENT_GENDER_RATIO_INFO, value);
      }
    },
    riskAssessmentNotes: {
      get() {
        return this.$store.state.common.excursionDc.riskAssessmentNotes;
      },
      set(value) {
        this.$store.commit(types.COMMON_SET_EXCURSION_RISK_ASSESSMENT_NOTES, value);
      }
    },
    riskAssessmentNotesDisplay() {
      return this.$store.state.common.excursionDc.riskAssessmentNotesEnabled;
    },
    getRatioElement() {
      return !!document.getElementById('student-gender-ratios');
    },
    mutipleLineDetected() {
      if (!this.formControlEnabled || this.archivedExcursionDetected) {
        if (this.staffToStudentGenderRatioInfo.length > 160) {
          return true;
        }
        const stringifiedText = JSON.stringify(this.staffToStudentGenderRatioInfo);
        if (stringifiedText.includes('\\n')) {
          return true;
        }
      }
      return false;
    },
    newExcursionDetected() {
      return (
        !this.$store.state.common.excursionDc.id ||
        `${this.$store.state.common.excursionDc.id}` === '0'
      );
    },
    excursionStartEndDateSetEnabled() {
      return (
        this.newExcursionDetected ||
        (!this.newExcursionDetected && this.$store.state.common.excursionStartEndDateSetEnabled)
      );
    },
    excursionStartEndDateSetRequired() {
      return (
        this.excursionStartEndDateSetEnabled &&
        ((typeof this.excursionStartDate !== 'undefined' && this.excursionStartDate !== '') ||
          (typeof this.excursionEndDate !== 'undefined' && this.excursionEndDate !== ''))
      );
    },
    mixedMedicalConditionHash() {
      return this.$store.state.common.mixedMedicalConditionHash;
    },
    disableDayExcursionEndDate() {
      return excursionTypeInfo?.DAY_EXCURSION.label === this.excursionType;
    }
  },

  methods: {
    getDownloadFileBtnId(filename) {
      return `excursion-builder--download-file--button-${filename}`;
    },
    formatExcursionDate(date) {
      return this.$app.excursionCommon.formatExcursionDate(date);
    },
    checkIfExcursionStartDateAllowed(date) {
      return this.$app.excursionCommon.checkIfExcursionStartEndDateSetValid({
        excursionStartDate: date,
        excursionEndDate: this.disableDayExcursionEndDate ? '' : this.excursionEndDate,
        excursionType: this.excursionType
      });
    },
    checkIfExcursionEndDateAllowed(date) {
      return this.$app.excursionCommon.checkIfExcursionStartEndDateSetValid({
        excursionStartDate: this.excursionStartDate,
        excursionEndDate: date,
        excursionType: this.excursionType
      });
    },
    handleExcursionStartEndDateIntegrity() {
      let excursionStartDateAllowed = true;
      if (this.excursionStartDate) {
        excursionStartDateAllowed = this.checkIfExcursionStartDateAllowed(this.excursionStartDate);
      }

      let excursionEndDateAllowed = true;
      if (this.excursionEndDate) {
        excursionEndDateAllowed = this.checkIfExcursionEndDateAllowed(this.excursionEndDate);
      }

      if (this.disableDayExcursionEndDate) {
        this.excursionEndDate = this.excursionStartDate;
      } else if (!(excursionStartDateAllowed && excursionEndDateAllowed)) {
        this.excursionEndDate = '';
      }
    },

    toggleStaffStudentSupervision(isShow) {
      this.staffStudentSupervisionDisplayed = isShow;
    },

    toggleStudentSelection(isShow) {
      this.studentSelectionDisplayed = isShow;
    },

    displayDetailsDialog(title, text) {
      const stringifiedText = JSON.stringify(text);
      const showedText = stringifiedText.replaceAll('\\n', '<br/>').replaceAll('"', '');
      this.genericDialogMessage = showedText;
      this.genericDialogTitle = title;
      this.genericDialogDisplayed = true;
    },
    getRiskAssessmentTrackableEventBaseArgHash() {
      const { apiDataDefaultJurisdiction, excursionDc } = this.$store.state.common;
      return this.$app.excursionCommon.getRiskAssessmentTrackableEventBaseArgHash(
        apiDataDefaultJurisdiction,
        excursionDc
      );
    },

    getRiskAssessmentTrackableEventOptionArgHash() {
      return this.$app.excursionCommon.getRiskAssessmentTrackableEventOptionArgHash();
    },

    registerRiskAssessmentBuilderViewUpdateEvent() {
      const trackableEventBaseArgHash = this.getRiskAssessmentTrackableEventBaseArgHash();
      const trackableEventOptionArgHash = this.getRiskAssessmentTrackableEventOptionArgHash();
      this.$app.eventPluginCommonAdapter.registerTrackableEvent(
        trackableEvent.RISK_ASSESSMENT_BUILDER_VIEW_UPDATE,
        trackableEventBaseArgHash,
        trackableEventOptionArgHash
      );
    },

    registerRiskAssessmentExportEvent() {
      const trackableEventBaseArgHash = this.getRiskAssessmentTrackableEventBaseArgHash();
      const trackableEventOptionArgHash = this.getRiskAssessmentTrackableEventOptionArgHash();
      this.$app.eventPluginCommonAdapter.registerTrackableEvent(
        trackableEvent.RISK_ASSESSMENT_EXCURSION_RISK_ASSESSMENT_EXPORT,
        trackableEventBaseArgHash,
        trackableEventOptionArgHash
      );
    },

    registerRiskAssessmentApprovalEvent() {
      const trackableEventBaseArgHash = this.getRiskAssessmentTrackableEventBaseArgHash();
      const trackableEventOptionArgHash = this.getRiskAssessmentTrackableEventOptionArgHash();
      this.$app.eventPluginCommonAdapter.registerTrackableEvent(
        trackableEvent.RISK_ASSESSMENT_APPROVAL,
        trackableEventBaseArgHash,
        trackableEventOptionArgHash
      );
    },

    registerRiskAssessmentUnapprovalEvent() {
      const trackableEventBaseArgHash = this.getRiskAssessmentTrackableEventBaseArgHash();
      const trackableEventOptionArgHash = this.getRiskAssessmentTrackableEventOptionArgHash();
      this.$app.eventPluginCommonAdapter.registerTrackableEvent(
        trackableEvent.RISK_ASSESSMENT_UNAPPROVAL,
        trackableEventBaseArgHash,
        trackableEventOptionArgHash
      );
    },

    registerRiskManifestLinkClickEvent() {
      this.excursionCommonAdapter.registerRiskManifestLinkClickEvent();
    },

    async submit(pdfType, statusChanged, callback) {
      const apiQuery = JSON.parse(JSON.stringify(this.$store.state.common.apiParam));
      const excursionMetadata = JSON.parse(JSON.stringify(this.$store.state.common.excursionDc));
      this.excursionId = excursionMetadata.id;
      delete excursionMetadata.id;
      delete excursionMetadata.additionalInformationItems;
      delete excursionMetadata.validCategories;
      delete excursionMetadata.computed;
      delete excursionMetadata.auditList;
      delete excursionMetadata.selectedLikelihood;
      delete excursionMetadata.selectedConsequence;
      delete excursionMetadata.selectedRating;

      excursionMetadata.name = this.name;
      excursionMetadata.typeId = this.excursionType;
      excursionMetadata.startDate = this.excursionStartDate;
      excursionMetadata.endDate = this.excursionEndDate;

      if (!excursionMetadata.studentMedicalConditionInfo) {
        excursionMetadata.studentMedicalConditionInfo = {};
      }
      excursionMetadata.studentMedicalConditionInfo.mixedMedicalConditionHash =
        this.mixedMedicalConditionHash;

      excursionMetadata.riskOverallUserRating = {
        likelihood: this.$store.state.common.excursionDc.selectedLikelihood,
        consequence: this.$store.state.common.excursionDc.selectedConsequence,
        rating: this.$store.state.common.excursionDc.selectedRating
      };

      excursionMetadata.riskAssessmentNotes = this.riskAssessmentNotes;

      const apiPayload = {
        metadata: excursionMetadata
      };

      if (!pdfType && !callback) {
        this.excursionSaveActionInProgress = true;
      }

      const excursionRiskCombo = await this.excursionCommonAdapter.getExcursionRiskCombo(
        this.$route.query
      );
      if (excursionRiskCombo) {
        apiPayload.metadata.riskOverall = excursionRiskCombo.riskOverall;
        apiPayload.metadata.riskCategories = excursionRiskCombo.riskCategories;
      }

      for (let i = 0; i < this.additionalInformationItems.length; i += 1) {
        this.additionalInformationItems[i].orderId = i + 1;
      }

      await this.insertAdditionalInformationItems(this.additionalInformationItems);

      apiPayload.metadata.additionalInformationNotes = this.additionalInformationItems
        .filter((item) => !item.isDeleted)
        .map((item) => {
          const additionalItem = {
            orderId: item.orderId,
            comment: item.comment,
            fileSummary: item.fileSummary,
            notes: item.notes
          };
          if (item.sid) {
            additionalItem.sid = item.sid;
          }
          return additionalItem;
        });

      if (`${this.excursionId}` !== '0') {
        // set updateSignature
        if (!apiPayload.metadata.updateSignature) {
          apiPayload.metadata.updateSignature = this.$app.uuid.v4();
        }

        const argHash = {
          store: this.$store,
          queryParam: apiQuery,
          id: this.excursionId,
          payload: apiPayload
        };
        const putExcursionsCbResponse = await this.$app.stbApiAdapter.putExcursions(argHash);

        if (
          !this.$store.state.common.isResponseWithError &&
          !pdfType &&
          (!callback || typeof callback !== 'function')
        ) {
          if (!putExcursionsCbResponse.error) {
            this.excursionSetByExcursionChanges = true;
            this.excursionSetByRiskChanges = true;
            this.$store.commit(types.COMMON_SET_EXCURSION, putExcursionsCbResponse);
            this.validateAgreementConfirmationTick();
            if (
              this.valuesChanged &&
              statusChanged &&
              !this.$store.state.common.excursionDc.agreementConfirmation.ticked
            ) {
              this.excursionStatusChanged = true;
              const putExcursionStatusesArgHash = {
                queryParam: apiQuery,
                newStatus: excursionStatus.DRAFT,
                store: this.$store,
                id: this.excursionId
              };
              await this.$app.stbApiAdapter.putExcursionStatuses(putExcursionStatusesArgHash);
            }

            this.excursionSaveActionInProgress = false;
            this.successDialogDisplayed = true;
          } else {
            this.excursionSaveActionInProgress = false;
            this.errorMessage = putExcursionsCbResponse.message;

            this.errorMessage = populateErrorMessage({
              message: this.errorMessage,
              store: this.$store
            });

            this.failureDialogDisplayed = true;
          }
        } else {
          this.excursionSaveActionInProgress = false;
          this.excursionSetByExcursionChanges = true;
          this.excursionSetByRiskChanges = true;
          this.$store.commit(types.COMMON_SET_EXCURSION, putExcursionsCbResponse);
          this.validateAgreementConfirmationTick();
        }

        this.valuesChanged = false;

        if (callback && typeof callback === 'function') {
          this.presaveConfirmed = true;
          await callback();
        }
      } else {
        const argHash = {
          store: this.$store,
          queryParam: apiQuery,
          payload: apiPayload
        };
        const postExcursionsCbResponse = await this.$app.stbApiAdapter.postExcursions(argHash);

        if (!this.$store.state.common.isResponseWithError && !pdfType) {
          if (!postExcursionsCbResponse.error) {
            if (postExcursionsCbResponse.data.id) {
              this.excursionId = postExcursionsCbResponse.data.id;
              this.excursionSetByExcursionChanges = true;
              this.excursionSetByRiskChanges = true;
              this.$store.commit(types.COMMON_SET_EXCURSION, postExcursionsCbResponse);
              this.validateAgreementConfirmationTick();
              const query = JSON.parse(JSON.stringify(this.$route.query));
              this.$router.replace(`/excursions/builder/${this.excursionId}`);

              if (query) {
                this.$router.replace({ query });
              }

              this.excursionSaveActionInProgress = false;
              this.successDialogDisplayed = true;
              this.pageRefreshRequired = true;
            } else {
              this.excursionSaveActionInProgress = false;
              this.successDialogDisplayed = true;
            }
          } else {
            this.excursionSaveActionInProgress = false;
            this.errorMessage = postExcursionsCbResponse.message;

            this.errorMessage = populateErrorMessage({
              message: this.errorMessage,
              store: this.$store
            });
            this.failureDialogDisplayed = true;
          }
        }

        this.valuesChanged = false;
        if (callback && typeof callback === 'function') {
          this.presaveConfirmed = true;
          await callback();
        }
      }

      if (this.excursionSaveActionInProgress) {
        return true;
      }
      return false;
    },

    resetJurisdiction() {
      this.$store.commit(types.COMMON_SET_EXCURSION_JURISDICTION, '');
    },

    handleExcursionTypeSelectionChange() {
      this.resetJurisdiction();
      this.handleExcursionStartEndDateIntegrity();
    },

    clearExcursionStartEndDateSet() {
      this.excursionStartDate = '';
      this.excursionEndDate = '';
    },
    clear() {
      const currentExcursionName = this.name;
      const currentExcursionType = this.excursionType;
      const currentVendorExcursionSid = this.vendorExcursionSid;
      if (!this.isExcursionInitiatedFromPcg) {
        this.clearExcursionStartEndDateSet();
      }

      const updateAdditionalInformationList = this.additionalInformationItems.filter(
        (a) => a.sid && a.sid.length > 0
      );
      updateAdditionalInformationList.forEach((a) => {
        const localA = a;
        localA.isDeleted = true;
      });
      this.updateAdditionalInformationList(updateAdditionalInformationList);

      this.resetConfirmationDialogDisplayed = false;
      this.$v.$reset();

      const excludedAttributeList = [
        'excursionTypeChoiceList',
        'userAuthSchemeApiParam',
        'apiParam',
        'excursionDc',
        'excursionAdditionalInformationEnabled',
        'excursionBuilderButtonDisplayed',
        'excursionCreationButtonDisplayed',
        'excursionDestinationContentEnabled',
        'excursionDisplayReady',
        'excursionHighRiskStudentMedicalEnabled',
        'excursionLogoDisplayReady',
        'excursionPersonResponsibleEnabled',
        'excursionStudentFetchTriggered',
        'excursionStudentMedicalConditionsDisplayReady',
        'logoAttachmentUrl',
        'providerUpdateActionEnabled',
        'excursionSyncButtonDisplayed',
        'userInitials',
        'vendorClientLogoEnabled',
        'vendorExcursionSidInputEnabled',
        'excursionStartEndDateSetEnabled',
        'clientSettingRiskMatrix',
        'clientUsersTabDisplayed',
        'usersTabDisplayed',
        'clientsTabDisplayed',
        'clientSiteSettingsButtonDiplayed',
        'clientSelfGroupManagementDisplayed',
        'publicSchoolFeatureSetEnabled',
        'riskCategoryManagementMenuItemDisplayed',
        'operationManagementMenuItemDisplayed',
        'studentManagementSubjectManagementDisplayed'
      ];
      const argHash = {
        excludedAttributeList,
        storeModuleName: ''
      };
      this.$store.commit(types.COMMON_RESET_STORE_MODULE_STATE, argHash);
      this.$store.commit(types.COMMON_RESET_EXCURSION_STORE_STATE, argHash);

      const isExcursionInitiatedFromPcg =
        this.$store.state.common.userAuthSchemeApiParam?.['vendor-sid'] === '10000001' &&
        !!currentVendorExcursionSid &&
        !this.newExcursionDetected;

      if (isExcursionInitiatedFromPcg) {
        this.vendorExcursionSid = this.vendorExcursionSidInputEnabled
          ? currentVendorExcursionSid
          : this.vendorExcursionSid;
        this.name = currentExcursionName;
        this.excursionType = currentExcursionType;
      }
      this.validateAgreementConfirmationTick();
    },

    toggleMandatoryChecklist() {
      this.mandatoryChecklistDisplayed = !this.mandatoryChecklistDisplayed;
    },

    confirmSuccess() {
      this.successDialogDisplayed = false;

      if (window.opener) {
        window.self.close();
      } else if (this.excursionStatusChanged || this.pageRefreshRequired) {
        window.location.reload();
      }
    },

    setOriginalExcursionType(value) {
      this.originalExcursionType = value;
    },

    async clearCategoriesOnExcursionTypeUpdate() {
      this.$store.commit(types.COMMON_SET_EXCURSION_TYPE, this.newExcursionType);
      this.originalExcursionType = this.newExcursionType;
      if (this.newExcursionType) {
        this.handleExcursionStartEndDateIntegrity();

        this.$store.commit(types.COMMON_SET_ALL_RISKS, []);
        this.$store.commit(types.COMMON_SET_EXCURSION_DC_CATEGORIES, []);
        await this.$store.dispatch('common/getAllRisksByExcursionTypeName', {
          stbApiAdapter: this.$app.stbApiAdapter,
          store: this.$store,
          queryParam: this.$route.query,
          excursionTypeName: this.newExcursionType
        });
      }
    },

    cancelExcursionTypeUpdate() {
      this.$store.commit(types.COMMON_SET_EXCURSION_TYPE, '');

      this.$nextTick(() => {
        this.$store.commit(
          types.COMMON_SET_EXCURSION_TYPE,
          this.originalExcursionType ? this.originalExcursionType : ''
        );
      });
    },

    async updateAdditionalInformationList(updateAdditionalInformationList) {
      if (!updateAdditionalInformationList || updateAdditionalInformationList.length === 0) {
        return;
      }

      updateAdditionalInformationList.forEach(async (item) => {
        if (!item.sid || (`${this.excursionId}` !== '0' && !(item.isUpdated || item.isDeleted))) {
          return;
        }

        if (item.isDeleted) {
          this.$refs.additionalInfoList.deleteAttachment(item);
        } else {
          const requestData = {};

          requestData.metadata = {
            summary: item.fileSummary,
            description: item.comment
          };

          if (
            typeof requestData.metadata.summary !== 'undefined' &&
            requestData.metadata.summary.trim() === ''
          ) {
            delete requestData.metadata.summary;
          }

          if (
            typeof requestData.metadata.description !== 'undefined' &&
            requestData.metadata.description.trim() === ''
          ) {
            delete requestData.metadata.description;
          }

          const argHash = {
            queryParam: this.$store.state.common.apiParam,
            excursionId: this.excursionId,
            attachmentSid: item.sid,
            payload: requestData,
            store: this.$store
          };
          await this.$app.stbApiAdapter.putExcursionAttachments(argHash);
        }
      });
    },
    async insertAdditionalInformationItems(additionalInformationItems) {
      const newAdditionalInformationItems = this.$app.lodash.cloneDeep(additionalInformationItems);
      if (newAdditionalInformationItems && newAdditionalInformationItems.length > 0) {
        // add
        const addAdditionalList = newAdditionalInformationItems.filter(
          (a) => typeof a.file !== 'undefined'
        );

        if (addAdditionalList && addAdditionalList.length > 0) {
          // Remove old additional files before adding new files
          const excursionId = this.$store.state.common.excursionDc.id;
          if (excursionId > 0) {
            addAdditionalList.forEach(async (item) => {
              const argHash = {
                queryParam: this.$store.state.common.apiParam,
                excursionId,
                attachmentSid: item.sid,
                store: this.$store
              };
              if (item.sid) {
                await this.$app.stbApiAdapter.deleteExcursionAttachments(argHash);
              }
            });
          }

          addAdditionalList.forEach(async (item) => {
            await this.postNewAdditionalFile(item);
          });
        }

        // update
        const updateAdditionalInformationList = newAdditionalInformationItems.filter(
          (a) => a.sid && a.sid.length > 0
        );

        await this.updateAdditionalInformationList(updateAdditionalInformationList);

        const existAdditions = newAdditionalInformationItems.filter((r) => !r.isDeleted);

        if (existAdditions && existAdditions.length === 0) {
          newAdditionalInformationItems.push({
            fileSummary: '',
            comment: '',
            showDeleteButton: false
          });
        }
        this.$store.commit(types.COMMON_SET_ADDITIONAL_INFORMATIONS, newAdditionalInformationItems);
      }
    },

    async postNewAdditionalFile(additionalItem) {
      const localAdditionalItem = additionalItem;
      const additionalItemList = [];
      const fd = new FormData();
      fd.append(`file-0`, localAdditionalItem.file);
      additionalItemList.push({
        summary: localAdditionalItem.fileSummary,
        description: localAdditionalItem.comment,
        orderId: localAdditionalItem.orderId
      });

      fd.append('items', JSON.stringify(additionalItemList));

      const argHash = {
        queryParam: this.$store.state.common.apiParam,
        excursionId: this.excursionId,
        payload: fd,
        store: this.$store
      };
      const addedRes = await this.$app.stbApiAdapter.postExcursionAttachments(argHash);
      addedRes.data.forEach((a) => {
        const file = a.metadata;
        localAdditionalItem.sid = a.sid;
        localAdditionalItem.comment = file.description;
        localAdditionalItem.filename = file.filename;
        localAdditionalItem.fileSummary = file.summary;
        localAdditionalItem.contentType = file.contentType;
        localAdditionalItem.orderId = file.orderId;
        const fileUpdateDate = new Date(a.created_at);
        if (fileUpdateDate.toString() !== 'Invalid Date') {
          localAdditionalItem.fileUpdateDate = fileUpdateDate.toLocaleString();
        }
      });

      return localAdditionalItem;
    },

    setAdditionalInformationValid(valid) {
      this.additionalInformationValid = valid;
      this.additionalInformationChanged = true;
    },

    clientLogoMarginDisplayed() {
      const { query } = this.$route;
      if (query.referrer || query['vendor-sid'] || query['oauth-signature']) {
        return false;
      }
      return true;
    },

    hasExcursion() {
      if (this.excursionId) {
        if (parseInt(this.excursionId, 10) > 0) {
          return false;
        }
      }
      return true;
    },

    setAgreementConfirmationTick() {
      this.valuesChanged = true;
      if (!this.agreementConfirmationManuallyTicked) {
        this.$store.commit(types.COMMON_SET_EXCURSION_CONFIRMATION_TICKED, false);
      } else {
        this.agreementConfirmationManuallyTicked = false;
      }
    },

    async saveChangesAsDraft() {
      if (this.valuesChanged || this.additionalInformationChanged) {
        this.additionalInformationChanged = false;
        this.changesSaveDialogDisplayed = true;
      } else {
        this.registerRiskAssessmentBuilderViewUpdateEvent();
        await this.submit(null, true);
      }
    },

    async saveChangesThenRevertToDraft() {
      this.registerRiskAssessmentBuilderViewUpdateEvent();
      this.$store.commit(types.COMMON_SET_EXCURSION_DC_STATUS, excursionStatus.DRAFT);
      this.$store.commit(types.COMMON_SET_EXCURSION_STEP, 1);
      this.changesSaveDialogDisplayed = false;
      await this.submit(null, true);
    },

    setAgreementConfirmationManuallyTicked(flag) {
      this.agreementConfirmationManuallyTicked = flag;
    },

    displayExcursionApprovalToggleDialog() {
      this.excursionApprovalToggleDialogDisplayed = true;
    },
    displayExcursionArchiveToggleDialog() {
      this.excursionArchiveToggleDialogDisplayed = true;
    },

    async unapproveExcursion() {
      const apiQuery = JSON.parse(JSON.stringify(this.$store.state.common.apiParam));
      const putExcursionStatusParams = {
        queryParam: apiQuery,
        newStatus: excursionStatus.SUBMITTED,
        store: this.$store,
        id: this.excursionId
      };

      this.excursionApprovalToggleActionInProgress = true;
      await this.$store.dispatch('common/unapproveExcursion', {
        stbApiAdapter: this.$app.stbApiAdapter,
        putExcursionStatusParams
      });

      this.excursionApprovalToggleActionInProgress = false;
      this.excursionApprovalToggleDialogDisplayed = false;
      this.$app.excursionCommon.enableFormControl('.excursion-builder', true);
      // ensure tickbox is ticked when status changed from approved to submitted
      this.agreementConfirmationManuallyTicked = true;
      const confirmInfo = {
        ...this.$store.state.common.excursionDc.agreementConfirmation,
        ticked: true
      };
      this.$store.commit(types.COMMON_SET_EXCURSION_CONFIRMATION, confirmInfo);
      this.validateAgreementConfirmationTick();
      this.registerRiskAssessmentUnapprovalEvent();
    },

    async approveExcursion() {
      this.excursionApprovalToggleDialogDisplayed = false;
      this.excursionApprovalToggleActionInProgress = true;
      const apiQuery = JSON.parse(JSON.stringify(this.$store.state.common.apiParam));
      const putExcursionStatusesArgHash = {
        queryParam: apiQuery,
        newStatus: excursionStatus.APPROVED,
        store: this.$store,
        id: this.excursionId
      };

      await this.$app.stbApiAdapter.putExcursionStatuses(putExcursionStatusesArgHash);
      this.excursionApprovalToggleActionInProgress = false;
      this.$store.commit(types.COMMON_SET_EXCURSION_DC_STATUS, excursionStatus.APPROVED);
      this.$app.excursionCommon.enableFormControl('.excursion-builder', false);
      this.excursionApprovalToggleDialogDisplayed = false;

      this.registerRiskAssessmentApprovalEvent();
    },

    async archiveExcursion() {
      this.excursionArchiveToggleDialogDisplayed = false;
      this.excursionArchiveToggleActionInProgress = true;
      const apiQuery = JSON.parse(JSON.stringify(this.$store.state.common.apiParam));
      const putExcursionStatusesArgHash = {
        queryParam: apiQuery,
        newStatus: excursionStatus.ARCHIVED,
        store: this.$store,
        id: this.excursionId
      };

      await this.$app.stbApiAdapter.putExcursionStatuses(putExcursionStatusesArgHash);
      this.excursionArchiveToggleActionInProgress = false;
      this.$store.commit(types.COMMON_SET_EXCURSION_DC_STATUS, excursionStatus.ARCHIVED);
      this.$app.excursionCommon.enableFormControl('.excursion-builder', false);
      this.excursionArchiveToggleDialogDisplayed = false;
    },

    async downloadFile(additional) {
      const apiQuery = JSON.parse(JSON.stringify(this.$store.state.common.apiParam));

      const argHash = {
        queryParam: apiQuery,
        sid: additional.sid,
        store: this.$store
      };
      const response = await this.$app.stbApiAdapter.getAttachments(argHash);
      if (response.data) {
        const attachments = response.data;
        if (attachments.length > 0) {
          const file = attachments[0];

          if (file && file.computed && file.metadata) {
            const filePath = file.computed.url.replace(/^\//, '');
            const apiUrl = this.$app.env.API_URL.replace(/\/$/, '');
            const fileUrl = `${apiUrl}/${filePath}`;
            const { filename } = file.metadata;
            this.excursionCommonAdapter.downloadFile(fileUrl, filename, this.$app.deviceDetector);
          }
        }
      }
    },

    pageLoadingAnimationDisplayed() {
      let loadingAnimationDisplayed = false;

      if (this.isInitClientConfigInProgress) {
        loadingAnimationDisplayed = true;
      } else if (
        this.isAuthenticated &&
        (!this.excursionDisplayReady ||
          !this.excursionLogoDisplayReady ||
          this.excursionSaveActionInProgress ||
          this.excursionApprovalToggleActionInProgress)
      ) {
        loadingAnimationDisplayed = true;
      }

      return loadingAnimationDisplayed;
    },

    validateAgreementConfirmationTick() {
      this.$nextTick(() => {
        if (this.$store.state.common.excursionDc.agreementConfirmation.ticked) {
          this.agreementConfirmationManuallyTicked = true;
        } else {
          this.agreementConfirmationManuallyTicked = false;
        }
      });
    },
    async updateRiskMatrix(query) {
      if (this.archivedExcursionDetected) {
        return;
      }
      const cbResponse = await this.$app.stbApiAdapter.getClientRiskMatrix({
        queryParam: query,
        store: this.$store
      });

      if (!cbResponse.error) {
        const {
          data: { likelihood, riskRating, consequence }
        } = cbResponse;
        this.$store.commit(types.COMMON_SET_CLIENT_SETTING_RISK_MATRIX, {
          likelihood,
          riskRating,
          consequence
        });
        this.$store.commit(types.COMMON_SET_RISK_MATRIX_SELF_DEFINED_DETECTED, true);
      } else {
        this.$store.commit(types.COMMON_SET_CLIENT_SETTING_RISK_MATRIX, {
          likelihood: defaultRiskMatrix.likelihood,
          consequence: defaultRiskMatrix.consequence,
          riskRating: defaultRiskMatrix.riskRating
        });
        this.$store.commit(types.COMMON_SET_RISK_MATRIX_SELF_DEFINED_DETECTED, false);
      }
    },
    setChecklistAttestationEnabled(argChecklistAttestationEnabled) {
      this.checklistAttestationEnabled = argChecklistAttestationEnabled;
    }
  },

  beforeDestroy() {
    this.$app.eventBus.$off('setAgreementConfirmationTick');
  },

  async mounted() {
    this.excursionCommonAdapter = new this.$app.excursionCommon.Adapter({
      store: this.$store,
      app: this.$app
    });
    const that = this;
    const { query, params } = this.$route;
    if (query && query[`excursion-id`]) {
      this.excursionId = query[`excursion-id`];
    }

    const apiQuery = JSON.parse(JSON.stringify(query));

    if (params && params.id) {
      this.excursionId = params.id;
      apiQuery[`excursion-id`] = this.excursionId;
    }
    this.validateAgreementConfirmationTick();
    this.updateRiskMatrix(query);

    this.$app.eventBus.$on('setAgreementConfirmationTick', this.setAgreementConfirmationTick);
    if (!this.propHasParentComponent) {
      const { axiosInstance, eventPluginCommonAdapter, stbApiAdapter } = this.$app;
      const isVendorAuthScheme =
        !!this.$route.query['vendor-sid'] && !!this.$route.query['oauth-signature'];
      const queryParam = isVendorAuthScheme ? this.$route.query : {};
      if (!isVendorAuthScheme) {
        await this.$store.dispatch('authorisation/validateAndSetUserTokenOrLogout', {
          query: this.$route.query,
          axiosInstance
        });
        if (this.$route.name === 'ExcursionBuilderExistingRecord' && params?.id > 0) {
          queryParam['excursion-id'] = params.id;
        }
      }

      await this.$store.dispatch('common/initClientConfig', {
        queryParam,
        eventPluginCommonAdapter,
        stbApiAdapter
      });
      if (
        this.isAuthenticated &&
        !this.excursionBuilderButtonDisplayed &&
        this.$route.name === 'ExcursionBuilderNewRecord'
      ) {
        this.$route.replace('/excursions');
      }
      this.$store.dispatch('common/setLogoImgUrl');

      if (this.isAuthenticated) {
        const getExcursionsArgHash = {
          stbApiAdapter,
          store: this.$store,
          excursionId: that.excursionId,
          query: apiQuery
        };
        await this.$store.dispatch('common/getExcursions', getExcursionsArgHash);
        const argHash = {
          stbApiAdapter,
          store: this.$store,
          queryParam: apiQuery
        };
        await this.$store.dispatch('common/updateProviders', argHash);
        await this.$store.dispatch('common/getExcursionTypes', argHash);
        await this.$store.dispatch('common/getDefaultJurisdiction', argHash);

        const predepartureArgHash = this.$app.lodash.cloneDeep(argHash);
        const genericFrcs = this.excursionCommonAdapter.getRiskCategoryParams();
        const activityFrcs = this.excursionCommonAdapter.getActivityParams();
        // frc stands for: factor risk category
        const frcs = genericFrcs.concat(activityFrcs);

        const excursionTypeName = this.$store.state.common.excursionDc.typeId;
        frcs.push([excursionTypeName]);
        predepartureArgHash.payload = {
          'risk-categories': frcs
        };

        if (frcs) {
          await this.$store.dispatch('common/postPredepartureStrategies', predepartureArgHash);
        }
        // check status
        if (excursionTypeName) {
          await this.$store.dispatch('common/getAllRisksByExcursionTypeName', {
            stbApiAdapter: this.$app.stbApiAdapter,
            store: this.$store,
            queryParam: query,
            excursionTypeName
          });
        }
      }
    }
    this.$nextTick(() => {
      if (this.resolvedExcursionStatus === excursionStatus.APPROVED) {
        this.$app.excursionCommon.enableFormControl('.excursion-builder', false);
      }
    });
  },

  watch: {
    excursions(newValue, oldValue) {
      if (
        excursionStatus.SUBMITTED === this.resolvedExcursionStatus &&
        !this.excursionSetByExcursionChanges
      ) {
        const oldExcursion = JSON.parse(JSON.stringify(oldValue));
        const newExcursion = JSON.parse(JSON.stringify(newValue));

        delete oldExcursion.updateSignature;
        delete newExcursion.updateSignature;
        delete oldExcursion.additionalInformationItems;
        delete newExcursion.additionalInformationItems;
        delete oldExcursion.predepartures;
        delete newExcursion.predepartures;
        delete oldExcursion.predepartureChecklistCompleted;
        delete newExcursion.predepartureChecklistCompleted;
        delete oldExcursion.auditList;
        delete newExcursion.auditList;

        if (!this.$app.lodash.isEqual(oldExcursion, newExcursion)) {
          this.valuesChanged = true;
          this.setAgreementConfirmationTick();
        }
      }
      this.excursionSetByExcursionChanges = false;
    },
    risks() {
      if (this.firstTimeLoaded && !this.excursionSetByRiskChanges) {
        if (excursionStatus.SUBMITTED === this.resolvedExcursionStatus) {
          this.valuesChanged = true;
          this.setAgreementConfirmationTick();
        }
      } else {
        this.firstTimeLoaded = true;
      }

      this.excursionSetByRiskChanges = false;
    },
    mixedMedicalConditionHash() {},
    excursionStartDate() {
      this.handleExcursionStartEndDateIntegrity();
    }
  }
};
</script>
