var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('cs-button',{class:_vm.btnClass,attrs:{"id":_vm.getCompleteChecklistId,"disabled":_vm.dialog,"loading":_vm.dialog,"left-icon":_vm.checklistCommentIconDisplayed ? 'fa fa-comment' : 'fa fa-check',"label":"Complete Checklist","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.getData()}}}),_c('cs-form-dialog',{attrs:{"id":"dlg-view-mandatory-checklist","bodyId":_vm.getDlgBodyId,"heading":"Complete Checklist","primaryAction":{
      label: 'Save',
      eventName: 'primary-click',
      disabled: !_vm.formControlEnabled
    },"secondaryAction1":{
      label: 'Close',
      eventName: 'secondary1-click'
    }},on:{"primary-click":_vm.save,"secondary1-click":_vm.close},scopedSlots:_vm._u([{key:"cs-form-dialog-content",fn:function(){return [_c('div',{staticClass:"checklist view-mandatory-checklist-policy"},[(_vm.checklist.title !== 'undefined' && _vm.dialog)?_c('v-list',[_c('v-subheader',[_c('h1',[_vm._v(_vm._s(_vm.checklist.title))])]),_vm._l((_vm.changedChildren),function(item,index){return _c('v-list',{key:index},[(item.contentType === 'form-group')?_c('div',[(item.label.indexOf(_vm.removeLabel) !== 0)?_c('v-subheader',[_c('vue-markdown',{attrs:{"source":item.label,"typographer":false}})],1):_vm._e(),_vm._l((item.children),function(option,index){return _c('div',{key:index},[_c('v-list-item',[('checkbox' === option.contentType)?_c('v-list-item-action',[_c('v-checkbox',{staticClass:"cbOption",attrs:{"color":"#c80063","checked":option.ticked,"id":("view-mandatory-checklist--" + (String(_vm.checklist.title).replaceAll(
                        ' ',
                        '-'
                      )) + "--form-group-checkbox--" + index),"disabled":!_vm.formControlEnabled},on:{"change":_vm.checklistOnChanged},model:{value:(option.ticked),callback:function ($$v) {_vm.$set(option, "ticked", $$v)},expression:"option.ticked"}})],1):_vm._e(),_c('v-list-item-content',[_c('vue-markdown',{attrs:{"source":option.label,"typographer":false}})],1)],1),_c('checklist-item-comment',{attrs:{"checklistItem":option,"commentBtnId":("view-mandatory-checklist--" + (String(_vm.checklist.title).replaceAll(
                    ' ',
                    '-'
                  )) + "--form-group-checkbox-comment--" + index)}})],1)})],2):(item.contentType === 'checkbox')?_c('div',[_c('v-list-item',[_c('v-list-item-action',[_c('v-checkbox',{staticClass:"cbOption",attrs:{"color":"#c80063","checked":item.ticked,"id":("view-mandatory-checklist--" + (String(_vm.checklist.title).replaceAll(
                      ' ',
                      '-'
                    )) + "--checkbox--" + index),"disabled":!_vm.formControlEnabled,"testing-id":"checkboxOptions"},on:{"change":_vm.checklistOnChanged},model:{value:(item.ticked),callback:function ($$v) {_vm.$set(item, "ticked", $$v)},expression:"item.ticked"}})],1),_c('v-list-item-content',[_c('vue-markdown',{attrs:{"source":item.label,"typographer":false}})],1)],1),_c('checklist-item-comment',{attrs:{"checklistItem":item,"commentBtnId":("view-mandatory-checklist--" + (String(_vm.checklist.title).replaceAll(
                  ' ',
                  '-'
                )) + "--checkbox-comment--" + index)}})],1):_c('div',[_c('v-subheader',[_c('vue-markdown',{attrs:{"source":item.label,"typographer":false}})],1),_c('checklist-item-comment',{attrs:{"checklistItem":item,"commentBtnId":("view-mandatory-checklist--" + (String(_vm.checklist.title).replaceAll(
                  ' ',
                  '-'
                )) + "--content-type-comment--" + index)}})],1)])})],2):_vm._e(),_c('div',{staticClass:"pl-4"},[_c('div',{staticClass:"d-flex align-center justify-start"},[_c('v-checkbox',{staticClass:"checkAll",attrs:{"color":"#c80063","checked":_vm.isCheckedAll,"id":"`check-all-list`","testing-id":"checkAllCheckbox","disabled":_vm.archivedExcursionDetected},on:{"change":_vm.checkAllOnchange},model:{value:(_vm.isCheckedAll),callback:function ($$v) {_vm.isCheckedAll=$$v},expression:"isCheckedAll"}}),_c('span',{staticClass:"check-all-label",attrs:{"for":"`check-all-list`"}},[_vm._v(" Select all ")])],1),_c('div',{staticClass:"pl-1"},[_vm._v(" The School requires that the mandatory risk control requirements contained in this checklist are implemented for all excursions. I acknowledge that I have read and understood the measures contained in this checklist, and they have all been or will all be implemented. ")])])],1)]},proxy:true}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }