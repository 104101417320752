<template>
  <div class="view-all-mandatory-checklist">
    <cs-button
      id="view-all-mandatory-checklist--view-checklist"
      label="View Checklist"
      :disabled="disabled"
      @click.stop="getData()"
    ></cs-button>
    <cs-form-dialog
      v-model="dialog"
      id="dlg-view-all-mandatory-checklist"
      bodyId="dlg-view-all-mandatory-checklist-body"
      heading="Complete Checklist"
    >
      <template v-slot:cs-form-dialog-content>
        <div class="checklist-content">
          <v-list v-for="(checklist, index) in checklists" :key="index">
            <v-subheader>
              <h1>{{ checklist.title }}</h1>
            </v-subheader>
            <v-list v-for="(item, index) in getItemsByTitle(checklist)" :key="index">
              <div v-if="item.contentType === 'form-group'">
                <v-subheader v-if="item.label.indexOf(removeLabel) !== 0">
                  <vue-markdown :source="item.label" :typographer="false"></vue-markdown>
                </v-subheader>
                <div v-for="(option, index) in item.children" :key="index">
                  <v-list-item>
                    <v-list-item-action v-if="'checkbox' === option.contentType">
                      <v-checkbox
                        color="#c80063"
                        v-model="option.ticked"
                        class="cbOption"
                        :checked="option.ticked"
                        :disabled="true"
                        :id="`view-all-mandatory-checklist--${String(checklist.title).replaceAll(
                          ' ',
                          '-'
                        )}--form-group-checkbox--${index}`"
                      ></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <label
                        class="text"
                        :for="`view-all-mandatory-checklist--${String(checklist.title).replaceAll(
                          ' ',
                          '-'
                        )}--form-group-checkbox--${index}`"
                      >
                        <vue-markdown :source="option.label" :typographer="false"></vue-markdown>
                      </label>
                    </v-list-item-content>
                  </v-list-item>
                  <checklist-item-comment
                    :checklistItem="option"
                    :commentDisabled="true"
                    :commentBtnId="`view-all-mandatory-checklist--${String(
                      checklist.title
                    ).replaceAll(' ', '-')}--form-group-checkbox-comment--${index}`"
                  >
                  </checklist-item-comment>
                </div>
              </div>
              <div v-else-if="item.contentType === 'checkbox'">
                <v-list-item>
                  <v-list-item-action>
                    <v-checkbox
                      color="#c80063"
                      v-model="item.ticked"
                      class="cbOption"
                      :checked="item.ticked"
                      :id="`view-all-mandatory-checklist--${String(checklist.title).replaceAll(
                        ' ',
                        '-'
                      )}--checkbox--${index}`"
                      :disabled="true"
                    ></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <label
                      class="text"
                      :for="`view-all-mandatory-checklist--${String(checklist.title).replaceAll(
                        ' ',
                        '-'
                      )}--checkbox--${index}`"
                    >
                      <vue-markdown :source="item.label" :typographer="false"></vue-markdown>
                    </label>
                  </v-list-item-content>
                </v-list-item>
                <checklist-item-comment
                  :checklistItem="item"
                  :commentDisabled="true"
                  :commentBtnId="`view-all-mandatory-checklist--${String(
                    checklist.title
                  ).replaceAll(' ', '-')}--checkbox-comment--${index}`"
                >
                </checklist-item-comment>
              </div>
              <div v-else>
                <v-subheader>
                  <vue-markdown :source="item.label" :typographer="false"></vue-markdown>
                </v-subheader>
                <checklist-item-comment
                  :checklistItem="item"
                  :commentDisabled="true"
                  :commentBtnId="`view-all-mandatory-checklist--${String(
                    checklist.title
                  ).replaceAll(' ', '-')}--content-type-comment--${index}`"
                >
                </checklist-item-comment>
              </div>
            </v-list>
          </v-list>
        </div>
      </template>
    </cs-form-dialog>
  </div>
</template>

<script>
export default {
  props: [],
  data: () => ({
    dialog: false,
    removeLabel: 'The following risk controls have been selected by default.'
  }),
  computed: {
    checklists() {
      return this.$store.state.common.excursionDc.mandatoryChecklist
        ? this.$store.state.common.excursionDc.mandatoryChecklist
        : [];
    }
  },
  methods: {
    getData() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    getItemsByTitle(obj) {
      if (!obj.Strategy) {
        return [];
      }

      const checklist = obj.Strategy.data[Object.keys(obj.Strategy.data)].children;
      const result = this.$app.lodash.cloneDeep(checklist);
      return result;
    }
  }
};
</script>
<style scoped>
.checklist-content .header {
  color: #c80063;
}

.checklist-content .v-list-item {
  height: auto;
  align-items: flex-start;
  padding-bottom: 12px;
}

.checklist-content .content {
  white-space: normal;
  height: auto;
}

.checklist-content h1 {
  color: gray;
  border-bottom: 3px solid #ff7f32;
  width: 100%;
}

.checklist-content .v-list-item__action {
  min-width: 30px;
  margin: 0 8px 0 0 !important;
  padding: 0 !important;
}

.checklist-content .v-list-item__content {
  margin: 0 !important;
  padding: 0 !important;
}

.checklist-content .v-input__slot {
  min-height: auto;
  height: auto;
}

.checklist-content .v-subheader {
  font-size: 16px !important;
}

.checklist-content .v-input--selection-controls__ripple:before {
  left: unset;
  right: unset;
  top: unset;
}

.checklist-content .v-list-item__content label {
  margin-bottom: 5px;
}
</style>
